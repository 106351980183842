<template>
  <cv-header aria-label="Carbon header">
    <button type="button" aria-haspopup="true" aria-controls="side-nav" aria-expanded="false" 
      class="cv-header-menu-button bx--header__action bx--header__menu-trigger bx--header__menu-toggle" 
      aria-label="Header menu">
        <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" 
          width="20" height="20" viewBox="0 0 20 20" aria-hidden="true">
          <path d="M2 14.8H18V16H2zM2 11.2H18V12.399999999999999H2zM2 7.6H18V8.799999999999999H2zM2 4H18V5.2H2z"></path>
        </svg>
    </button>
    <cv-header-name href="javascript:void(0)" prefix="iBiznafy">
      LACD Corp
    </cv-header-name>
    <template slot="header-global">
        <cv-header-global-action aria-label="Notifications">
        <notification-20 />
      </cv-header-global-action>
        <cv-header-global-action aria-label="User avatar">
          <user-avatar-20 />
        </cv-header-global-action>
        <cv-header-global-action aria-label="App switcher">
          <app-switcher-20 />
        </cv-header-global-action>
      </template>
  </cv-header>
</template>

<script>
  import { Notification20, UserAvatar20 } from '@carbon/icons-vue';
  export default {
    name: 'BiznafyLoginHeader',
    components: { Notification20, UserAvatar20 }
  };
</script>

<style lang="scss">
    svg.ibiznafy-taxonomy-icon {
      height: 1.25rem;
      margin-right: 0.5rem;
      min-width: 1.25rem;
      width: 1.25rem;
      fill: #ffffff;
      transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
    }

    .bx--side-nav {
      background-color: #161616;
    }
</style>
