<template>
    <biznafy-document-table
        :headers="data.headerList"
        :rows="data.dataTable"
        :paginationData="data.paginationObj"
        :use_content="true"
        :use_primaryButton="true"
        :use_secondaryButton="true"
        :use_expandingSlottedData="true"
        @add-new-row-evt = "addNewRowEvt"
        @delete-row-evt = "deleteRowEvt"
        @upload-pdf-file-evt = "uploadPdfFileEvt"/>
</template>

<script>

import BiznafyDocumentTable from './BiznafyDocumentTable';
export default {
    components: { BiznafyDocumentTable  },
    name: 'BiznafyDocument',
    emits: { "add-new-row-evt": null, "delete-row-evt": null, "upload-pdf-file-evt": null },
    props: { rows: Array, headers: Array, paginationData: Object  },
    computed: { 
        data() { return { headerList: this.headers, paginationObj: this.paginationData, dataTable: this.rows }; }
    },
    methods: {
        addNewRowEvt: function(evt){
            this.$emit("add-new-row-evt",  { obj: evt } );
        },
        deleteRowEvt: function(evt){
            this.$emit("delete-row-evt",  { obj: evt } );
        },
        uploadPdfFileEvt: function(evt){
            this.$session.set("FILE_EXTENSION", ".pdf");
            this.$emit("upload-pdf-file-evt",  { obj: evt } );
        }
    }
    
}
</script>

