<template>
  <cv-header aria-label="Carbon header">
    <cv-header-menu-button aria-label="Header menu" aria-controls="side-nav"/>
    <cv-header-name to="/" prefix="iBiznafy"> LACD Corp </cv-header-name>

    <template slot="left-panels">
      <cv-side-nav id="side-nav" rail>
      <cv-side-nav-items>

        <!-- cv-side-nav-link href="/#/dashboard">
          <template v-slot:nav-icon><Dashboard32 class = "ibiznafy-taxonomy-icon"/></template>
          Dashboard
        </cv-side-nav-link -->

        <!-- cv-side-nav-link href="/#/users">
          <template v-slot:nav-icon><UserMultiple20 class = "ibiznafy-taxonomy-icon"/></template>
          Users
        </cv-side-nav-link -->

        <!-- cv-side-nav-link href="/#/clients">
          <template v-slot:nav-icon><CustomerService20 class = "ibiznafy-taxonomy-icon"/></template>
          Clients
        </cv-side-nav-link -->

        <!-- cv-side-nav-link href="/#/reportUploader">
          <template v-slot:nav-icon><FolderParent20 class = "ibiznafy-taxonomy-icon"/></template>
          Report Uploader
        </cv-side-nav-link -->

        <cv-side-nav-menu title="Reports" active>
          <template v-slot:nav-icon><ChartCombo32 class = "ibiznafy-taxonomy-icon"/></template>
          <cv-side-nav-menu-item href="/#/propertyTaxReport" >
            Delinquent Tax
          </cv-side-nav-menu-item>
        </cv-side-nav-menu>

      </cv-side-nav-items>
    </cv-side-nav>
    </template>

    <template slot="header-global">
      <!-- cv-header-global-action aria-label="Notifications">
        <notification-20 />
      </cv-header-global-action -->
      <cv-header-global-action @click="logoutEvt">
        <logout-20 />
      </cv-header-global-action>
      <!-- cv-header-global-action aria-label="App switcher">
        <app-switcher-20 />
      </cv-header-global-action -->
    </template>

  </cv-header>
</template>


<script>
  import { ChartCombo32, Settings32, Logout20, Dashboard32, Notification20, UserMultiple20, CustomerService20, FolderParent20, AppSwitcher20 } from '@carbon/icons-vue'; 

  export default {
    name: 'BiznafyHomeHeader',
        components: { ChartCombo32, Settings32, Logout20, Dashboard32, Notification20, UserMultiple20, CustomerService20, FolderParent20, AppSwitcher20 },

    methods: {
      logoutEvt: function(evt){
        this.$session.destroy();
        this.$router.push("/");
      }
    }
  };
</script>

<style lang="scss">
  @import './carbon-overrides';
</style>
