class BackendServer {

    static env = {
        property : {
            local: {
                host: "http://localhost:3000"
            },
            dev: {
                host: "http://169.51.204.185:30148"
            }
        }
    }

    //Defined your enviroment in this line .dev .local
    static enviroment = BackendServer.env.property.local;

    //This properties are used to configura local/dev enviroment (Do not changes undearneath lines)
    static host = BackendServer.enviroment.host;

}

export default BackendServer;