<template>
    <cv-data-table
      row-size="sm"
      :sortable="true"
      :zebra="true"
      :staticWidth="false"
      :stickyHeader="false"
      :columns="columns"
      :use_htmlData="true"
      searchLabel="Filter"
      searchPlaceholder="Filter"
      searchClearLabel="Clear filter"
      class = "tittleAbstractingTableClass"
      ref="holderTable">

      <template v-slot:data>
          <template v-if="data.table.length">
            <cv-data-table-row v-for="(row, rowIndex) in data.table" :key="`${rowIndex}`" :value="`${rowIndex}`">
              <cv-data-table-cell><cv-text-input  :id="`holder_${rowIndex}_owner`"  :value="`${row.data[0]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`holder_${rowIndex}_name`"  :value="`${row.data[1]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`holder_${rowIndex}_address`"  :value="`${row.data[2]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`holder_${rowIndex}_city`"  :value="`${row.data[3]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`holder_${rowIndex}_phone`"  :value="`${row.data[4]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`holder_${rowIndex}_email`"  :value="`${row.data[5]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`holder_${rowIndex}_facebook`"  :value="`${row.data[6]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`holder_${rowIndex}_instagram`"  :value="`${row.data[7]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`holder_${rowIndex}_other`"  :value="`${row.data[8]}`"> </cv-text-input></cv-data-table-cell>
              <input type="hidden" :id="`holder_${rowIndex}__id`"  :value="`${row.data[9]}`" />
              <input type="hidden" :id="`holder_${rowIndex}_accountNumber`"  :value="`${row.data[10]}`" />
            </cv-data-table-row>
          </template>
      </template>

      <template slot="actions">
        <cv-data-table-action @click="addNewRowEvt">
          <AddAlt32/>
        </cv-data-table-action>
      </template>

      <template slot="batch-actions">
        <cv-button @click="deleteRowEvt">
          Delete
          <TrashCan16 class="bx--btn__icon"/>
        </cv-button>
        <!-- <cv-button @click="editRowEvt">
          Edit
          <Edit16 class="bx--btn__icon"/>
        </cv-button>
        -->
      </template>

    </cv-data-table>

</template>

<script>

import { AddAlt32, TrashCan16 } from '@carbon/icons-vue';

export default {
  components: { AddAlt32, TrashCan16 },
  name: 'BiznafyInterestHolderContactTable',
  emits: { "add-new-row-evt": null, "delete-row-evt": null },
  props: {
    headers: Array,
    rows: Array,
    paginationData: Object,
    title: String,
    helperText: String,
    inlineLoadingState: String,
    inlineLoadedText: String,
    inLineLoadingText: String
  },
  computed: {
    columns() {
      return this.headers.map(header => header.header);
    },
    data() {
      return {
        table: this.rows.map(row => ({
            data: [
            row.owner,
            row.name,
            row.address,
            row.city,
            row.phone,
            row.email,
            row.facebook,
            row.instagram,
            row.other,
            row._id,
            row.accountNumber
        ]}))
      }
    },
  },
  methods: {
    addNewRowEvt: function(evt){
      this.$emit("add-new-row-evt",  { obj: evt } );
    },
    deleteRowEvt: function(){
      let newRows = this.rows.filter( (item, index, array) => {
        return !this.$refs.holderTable.selectedRows.includes(index.toString());
      });

      this.$emit("delete-row-evt", newRows );
      this.$refs.holderTable.deselect();
    }
  }
};
</script>

<style lang="scss">
    @import '../../styles/carbon-utils';
    @import './carbon-overrides-table';
    //@import '@/vue-ui/styles/skeleton';
</style>