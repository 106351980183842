import BackendServer from '@/vue-ui/services/common/BackendServer.js';
import axios from 'axios';

//Municipal Liens Service
class MunicipalLiensService {

    getHistoricalByAccountNumber = async (accountNumber) => {
        const { data } = await axios.post('/municipalLienRoute/getHistoricalByAccountNo',
            { "accountNumber": accountNumber }
        );

        return data;
    }
}

export default MunicipalLiensService;

