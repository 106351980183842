import BackendServer from '@/vue-ui/services/common/BackendServer.js';
import Ajax from '@/vue-ui/services/common/Ajax.js';
import axios from 'axios';
import $ from 'jquery';

//Property Tax Report Service
class PropertyTaxReportService {

  buildRequestFilterData = (page, pageLength, filterData) => {

    let filter = {
      "CURRENT_PAGE": page,
      "PAGE_SIZE": pageLength,
      "FILTER" : {
          "ACCOUNT_NUMBER": filterData ? filterData.accountNumber : "",
          "PROPERTY_TYPE": filterData ? filterData.propertyType : "R",
          "SITUS_LOCATION_CITY": filterData ? filterData.situsLocationCity : "",
          "OWNER_STATE": filterData ? filterData.ownerState : "",
          "WITH_CAUSE_NUMBER": filterData ? filterData.withCauseNumber : true,
          "WITH_LEAD": filterData ? filterData.withLead : false,

          "APPLY_APPRAISED": filterData ? filterData.appraisedValueCheck : false,
          "APPRAISED_VALUE": {"GTE": filterData ? filterData.appraisedValueLower : 0, "LTE": filterData ? filterData.appraisedValueUpper : 0},

          "APPLY_TOTAL_DUE": filterData ? filterData.totalDueCheck : false,
          "TOTAL_DUE": {"GTE": filterData ? filterData.totalDueLower : 0, "LTE": filterData ? filterData.totalDueUpper : 0},

          "APPLY_DELQ_YEARS": filterData ? filterData.delqYearCheck : false,
          "DELQ_YEARS": {"GTE": filterData ? filterData.delqYearLower : 0, "LTE": filterData ? filterData.delqYearUpper : 0},
      }
    };

    return filter;
  }

  buildFilterObjByEnteredData = () => {
    let withCauseNumber = $('#withCauseNumber').is(":checked");
    let appraisedValueCheck = $('#appraisedValueCheck').is(":checked");
    let totalDueCheck = $('#totalDueCheck').is(":checked");
    let delqYearCheck = $('#delqYearCheck').is(":checked");
    let withLead = $('#withLead').is(":checked");


    let filterData = {
      accountNumber: $('#accountNumber').val(),
      propertyType: $('#propertyType').val(),
      situsLocationCity: $('#situsLocationCity').val(),
      ownerState: $('#ownerState').val(),
      appraisedValueLower: parseInt($('#appraisedValueLower').val()),
      appraisedValueUpper: parseInt($('#appraisedValueUpper').val()),
      totalDueLower: parseInt($('#totalDueLower').val()),
      totalDueUpper: parseInt($('#totalDueUpper').val()),
      delqYearLower: parseInt($('#delqYearLower').val()),
      delqYearUpper: parseInt($('#delqYearUpper').val()),
      withCauseNumber: withCauseNumber,
      appraisedValueCheck: appraisedValueCheck,
      totalDueCheck: totalDueCheck,
      delqYearCheck: delqYearCheck,
      withLead: withLead
    }

    return filterData;
  }

  getTotalRecords = () => {
    const ajax = new Ajax();
    let response = ajax.call('POST', '/propertyTaxReportRoute/getTotalRecords', this.buildRequestFilterData(1, null));
    return response !== undefined ? response.TOTAL_RECORDS : 0;
  }

  getUniqueById = (accountNo) => {
    const ajax = new Ajax();
    let response = ajax.call('POST', '/propertyTaxReportRoute/getUniqueById', { ACCOUNT_NUMBER: accountNo});
    return response;
  }

  search = async (term, page, session) => {
    const pageLength  =  session.get("PAGE_LENGTH");
    const sortObj  =  session.get("PROPERTY_TAX_REPORT_SORT_OBJ");
    const { data } = await axios.post('/propertyTaxReportRoute/search',

      { 
        CURRENT_PAGE: page,
        PAGE_SIZE: pageLength,
        SORT: sortObj,
        SEARCH: {
          TERM: term
        }
      });

    return data;
  }

  saveDetail = (detailData) => {
    const ajax = new Ajax();
    let response = ajax.call('POST', '/propertyTaxReportRoute/addUpdateDetail', detailData);
    return response;
  }

  updateTableDisplayedData = async (page, session) => {
    const filterObjByEnteredData =  session.get("PROPERTY_TAX_REPORT_FILTER_DATA");
    const pageLength  =  session.get("PAGE_LENGTH");
    const sortObj  =  session.get("PROPERTY_TAX_REPORT_SORT_OBJ");
    const requestFilterData = this.buildRequestFilterData(page, pageLength, filterObjByEnteredData);

    if(sortObj){ requestFilterData["SORT"] = sortObj; }

    const { data } = await axios.post('/propertyTaxReportRoute/findAllPaginated', requestFilterData);
    return data;
  }

  updateWorkOnLeadStatus = (accountNumber, workOnLead) => {
    const { data } = axios.post('/propertyTaxReportRoute/updateWorkOnLeadStatus', 
      {accountNumber: accountNumber, workOnLead: workOnLead});

      return data;
  }

  downloadDataAsExcelFile = async (page, pageLength, session) => {
    const filterObjByEnteredData =  session.get("PROPERTY_TAX_REPORT_FILTER_DATA");
    const requestFilterData = this.buildRequestFilterData(page, pageLength, filterObjByEnteredData);

    const { data } = await axios.post('/propertyTaxReportRoute/findAll', requestFilterData);
    return data;
  }

  count = async (page, session) => {
    const filterObjByEnteredData =  session.get("PROPERTY_TAX_REPORT_FILTER_DATA");
    const pageLength  =  session.get("PAGE_LENGTH");
    const requestFilterData = this.buildRequestFilterData(page, pageLength, filterObjByEnteredData);

    const { data } = await axios.post('/propertyTaxReportRoute/count', requestFilterData);
    return data;
  }
}

export default PropertyTaxReportService;

