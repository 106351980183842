<template>
    <cv-data-table
      row-size="sm"
      :sortable="true"
      :zebra="true"
      :staticWidth="false"
      :stickyHeader="false"
      :columns="columns"
      :use_htmlData="true"
      searchLabel="Filter"
      searchPlaceholder="Filter"
      searchClearLabel="Clear filter"
      class = "documentTableClass"
      ref="mediaTable">

      <template v-slot:data>
          <template v-if="data.table.length">
            <cv-data-table-row v-for="(row, rowIndex) in data.table" :key="`${rowIndex}`" :value="`${rowIndex}`">

              <cv-data-table-cell>
                <cv-select :id="`media_${rowIndex}_mediaType`"  v-model="row.data[0]">
                  <cv-select-option disabled selected hidden value>Choose an option</cv-select-option>
                  <cv-select-option value="case-roa">Case ROA</cv-select-option>
                  <cv-select-option value="original-petition">Original Petition</cv-select-option>
                  <cv-select-option value="amended-petition">Amended Petition</cv-select-option>
                  <cv-select-option value="citation">Citation</cv-select-option>
                  <cv-select-option value="service-return">Service Return</cv-select-option>
                  <cv-select-option value="org-answer">Org. Answer</cv-select-option>
                  <cv-select-option value="motion">Motion</cv-select-option>
                  <cv-select-option value="judgment">Judgment</cv-select-option>
                  <cv-select-option value="order-of-sale">Order Of Sale</cv-select-option>
                  <cv-select-option value="other-order">Other Order</cv-select-option>
                  <cv-select-option value="other-document">Other Document</cv-select-option>
                </cv-select>
              </cv-data-table-cell>  

              <cv-data-table-cell><cv-date-picker :id="`media_${rowIndex}_mediaDate`"  kind="single" :value="`${row.data[1]}`" class = "detailDocumentDatePickerClass"> </cv-date-picker></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`media_${rowIndex}_mediaDescription`"  :value="`${row.data[2]}`"> </cv-text-input></cv-data-table-cell>
              
              <input type="hidden" :id="`media_${rowIndex}_uuid`"  :value="`${row.data[5]}`" />
              <input type="hidden" :id="`media_${rowIndex}_isuploaddisabled`"  :value="`${row.data[6]}`" />
              <input type="hidden" :id="`media_${rowIndex}_isdownloaddisabled`"  :value="`${row.data[7]}`" />
              <input type="hidden" :id="`media_${rowIndex}_temporallink`"  :value="`${row.data[8]}`" />
              <input type="hidden" :id="`index-${row.data[5]}`"  :value="`${rowIndex}`" />


              <cv-data-table-cell>
                <div class = "sameLineClass">
                  <cv-text-input  :id="`media_${rowIndex}_mediaName`"  :value="`${row.data[3]}`"> </cv-text-input>
                </div>
              </cv-data-table-cell>

              <cv-data-table-cell>
                  <div class = "rowTableButton">
                    <button :id="`upload-${row.data[5]}`" role="button" 
                      class="cv-button bx--btn bx--btn--primary pdfDocumentButtonClass" 
                      :disabled="row.data[6] === 'true' ? true : false " @click="uploadPdfFileEvt(`${row.data[5]}`)">
                      Upload File <ImageReference16 class = "bx--btn__icon pdfDocumentIconClass"/>
                    </button>
                  </div>
              </cv-data-table-cell>

              <cv-data-table-cell>
                  <div class = "rowTableButton">
                    <button :id="`download-${row.data[5]}`" role="button" 
                      class="cv-button bx--btn bx--btn--primary pdfDocumentButtonClass" 
                      :disabled="row.data[7] === 'true' ? true : false" @click="downloadPdfFileEvt(`${row.data[8]}`)">
                      Download File <WatsonHealthDownloadStudy16 class = "bx--btn__icon pdfDocumentIconClass"/>
                    </button>
                  </div>
              </cv-data-table-cell>

            </cv-data-table-row>
          </template>
      </template>

      <template slot="actions">
        <cv-data-table-action @click="addNewRowEvt">
          <AddAlt32/>
        </cv-data-table-action>
      </template>

      <template slot="batch-actions">
        <cv-button @click="deleteRowEvt">
          Delete
          <TrashCan16 class="bx--btn__icon"/>
        </cv-button>
      </template>

    </cv-data-table>

</template>

<script>

import { AddAlt32, TrashCan16, ImageReference16, WatsonHealthDownloadStudy16 } from '@carbon/icons-vue';
import DropBoxService from '@/vue-ui/services/common/DropBoxService.js';
const jquery = require('jquery');

export default {
  components: { AddAlt32, TrashCan16, ImageReference16, WatsonHealthDownloadStudy16 },
  name: 'BiznafyDocumentTable',
  emits: { "add-new-row-evt": null, "delete-row-evt": null, "upload-pdf-file-evt": null },
  props: {
    headers: Array,
    rows: Array,
    paginationData: Object,
    title: String,
    helperText: String,
    inlineLoadingState: String,
    inlineLoadedText: String,
    inLineLoadingText: String
  },
  computed: {
    columns() {
      return this.headers.map(header => header.header);
    },
    data() {
      return {
        table: this.rows.map(row => ({
            data: [
            row.mediaType,
            row.mediaDate,
            row.mediaDescription,
            row.mediaName,
            row.mediaFile,
            row.uuid,
            row.isuploaddisabled,
            row.isdownloaddisabled,
            row.temporallink
        ]}))
      }
    },
  },
  methods: {
    addNewRowEvt: function(evt){
      this.$emit("add-new-row-evt",  { obj: evt } );
    },
    deleteRowEvt: function(evt){
      let newRows = this.rows.filter( (item, index, array) => {
        return !this.$refs.mediaTable.selectedRows.includes(index.toString());
      });

      this.$emit("delete-row-evt", newRows );
      this.$refs.mediaTable.deselect();
    },
    uploadPdfFileEvt: function(evt){
      this.$session.set('FILE_UPLOADER_TAB_TYPE', "media");
      this.$emit("upload-pdf-file-evt", evt );
    },
    downloadPdfFileEvt: async function(evt){
      const dropBoxService = new DropBoxService();
      let tokenData = await dropBoxService.generateAccessToken();
      let tempLink = await dropBoxService.createTemporalLink(evt, tokenData.access_token);

      var link = document.createElement('a');
      link.href = tempLink.link;

      document.body.appendChild(link);
      link.click();
    }
  }
};


</script>

<style lang="scss">
    @import '../../styles/carbon-utils';
    @import './carbon-overrides-table';
    //@import '@/vue-ui/styles/skeleton';

    .pdfDocumentIconClass {
      fill: #ffffff
    }

    .sameLineClass {
      display: flex;
    }

    .documentTableClass .bx--data-table-container .bx--data-table-content .bx--data-table--zebra tbody tr:not(.bx--parent-row):nth-child(even) td{
      border-top: none;
      border-bottom: none;
      background-color: rgb(0, 0, 0);
    }

    .documentTableClass .bx--data-table-container .bx--data-table-content .bx--data-table--zebra tbody tr:not(.bx--parent-row):nth-child(odd) td{
      border-top: none;
      border-bottom: none;
      background-color: rgb(0, 0, 0);
    }

    .documentTableClass .bx--data-table-container .bx--table-toolbar .bx--toolbar-content {
      background-color: rgb(0, 0, 0);
    }

    .bx--table-sort__icon {
      fill: white;
    }

    .bx--table-sort__icon-unsorted{
      fill: white;
    }

    .pdfDocumentButtonClass {
      width: 100%;
      min-height: 2rem;
    }

    .rowTableButton {
      padding-top: 0.6rem;
    }

    .bx--date-picker-container {
      padding-top: 0.6rem;
    }

    .detailDocumentDatePickerClass{
      width: 13.8rem;
    }
</style>