<template>
  <div class="cv-text-input bx--form-item bx--text-input-wrapper">
	<label :for="data.inputId" class="bx--label">{{data.inputLabel}}</label>
	<div class="bx--text-input__field-wrapper">
		<input  :id="data.inputId" 
            :value="formattedValue"  
            ref="inputRef" type="text" 
            class="bx--text-input"
            @change="onChangeEvt"/>
	</div>
</div>

</template>

<script>

  import { watch } from 'vue'
  import { useCurrencyInput } from 'vue-currency-input'

  export default {
    name: 'CurrencyInput',
    emits: { "change": null },
    props: {
      options: Object,
      id: String,
      label: String,
      value: Number
    },
    computed: {
      data () {
        return {
          inputId: this.id,
          inputLabel: this.label
        }
      }
    },
    methods: {
      onChangeEvt: function(evt){
        this.$emit("change",  evt);
      }
    },
    setup (props) {
      const {
        inputRef,
        formattedValue,
        setOptions,
        setValue
      } = useCurrencyInput(props.options)

      watch(() => props.value, (value) => {
        setValue(value)
      })

      watch(() => props.options, (options) => {
        setOptions(options)
      })

      return { inputRef, formattedValue }
    }
}
</script>