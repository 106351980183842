<template>
  <div>
    <cv-data-table
      row-size="xs"
      :auto-width="autoWidth"
      title="Delinquent Tax Report"
      :sortable="true"
      :action-bar-aria-label="actionBarAriaLabel"
      :batch-cancel-label="batchCancelLabel"
      :zebra="true"
      :staticWidth="true"
      :stickyHeader="false"
      :columns="columns"
      @search="onFilter"
      searchLabel="Filter"
      searchPlaceholder="Filter"
      searchClearLabel="Clear filter"
      :pagination="paginationData"
      @pagination="$emit('pagination', $event)"
      v-model="rowSelects"
      @row-select-change="actionRowSelectChange"
      @sort="propertyTaxReportOnSortEvt"
      :overflow-menu="sampleOverflowMenu"
      :helper-text="helperText"
      :data="filteredData"
      @overflow-menu-click="onOverflowMenuClick"
      class = "propertyTaxReportTableClass">

      <template v-slot:data>
          <template v-if="data.table.length != 0">
            <cv-data-table-row v-for="(row, rowIndex) in data.table" :key="`${rowIndex}`">
              <cv-data-table-cell v-for="(cell, cellIndex) in row.data" :key="`${cellIndex}`">
                <div class = "accountNumber" v-if="cellIndex === 0">
                  <a href="#" v-bind:class="showMore" 
                    @click="openPropertyTaxAccountDetail($event, `${row.data[7]}`,`${row.data[8]}`)" :id="`${cell}`">
                      {{ cell }}
                  </a>
                </div>
                <div v-else-if="cellIndex === 11">
                  <div v-if="row.data[11] === true">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10 1.25C7.97537 1.24982 6.01331 1.95167 4.44815 3.23596C2.88299 4.52026 1.81158 6.30752 1.41647 8.29323C1.02136 10.2789 1.327 12.3402 2.28132 14.1258C3.23563 15.9114 4.77957 17.3109 6.65006 18.0857C8.52055 18.8606 10.6018 18.9629 12.5393 18.3752C14.4768 17.7876 16.1505 16.5463 17.2754 14.8629C18.4002 13.1795 18.9065 11.1581 18.7081 9.14322C18.5096 7.12834 17.6187 5.2446 16.187 3.813C14.5461 2.17213 12.3206 1.25021 10 1.25ZM8.75 13.494L5.625 10.369L6.619 9.375L8.75 11.506L13.381 6.875L14.381 7.866L8.75 13.494Z" fill="#42BE65"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75 13.494L5.625 10.369L6.619 9.375L8.75 11.506L13.381 6.875L14.378 7.866L8.75 13.494Z" fill="#161616"/>
                      </svg>
                      <span>Yes</span>
                    </div>
                  </div>
                  <div v-else>
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
                        <title>circle-dash</title>
                        <g id="Structure" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <path d="M4.8125,2.9375 C4.09562055,3.48761241 3.46381888,4.14047413 2.9375,4.875 L3.9375,5.625 C4.39652935,4.98839236 4.94366116,4.42021702 5.5625,3.9375 L4.8125,2.9375 Z" id="Path" fill="#4589ff" fill-rule="nonzero"/>
                          <path d="M2.875,7.6875 L1.6875,7.3125 C1.38560165,8.17584944 1.23752336,9.08547324 1.25,10 L2.5,10 C2.49746364,9.21382769 2.62415198,8.43258295 2.875,7.6875 L2.875,7.6875 Z" id="Path" fill="#4589ff" fill-rule="nonzero"/>
                          <path d="M1.6875,12.75 C1.97735132,13.6215221 2.39889972,14.4435415 2.9375,15.1875 L3.9375,14.4375 C3.48567465,13.8048658 3.12779777,13.1101636 2.875,12.375 L1.6875,12.75 Z" id="Path" fill="#4589ff" fill-rule="nonzero"/>
                          <path d="M4.875,17.0625 C5.61895852,17.6011003 6.44097791,18.0226487 7.3125,18.3125 L7.6875,17.125 C6.95233641,16.8722022 6.25763423,16.5143254 5.625,16.0625 L4.875,17.0625 Z" id="Path" fill="#4589ff" fill-rule="nonzero"/>
                          <path d="M7.3125,1.6875 L7.6875,2.875 C8.43258295,2.62415198 9.21382769,2.49746364 10,2.5 L10,1.25 C9.08547324,1.23752336 8.17584944,1.38560165 7.3125,1.6875 L7.3125,1.6875 Z" id="Path" fill="#4589ff" fill-rule="nonzero"/>
                          <path d="M15.125,17.0625 C15.8611823,16.5138203 16.5138203,15.8611823 17.0625,15.125 L16.0625,14.375 C15.597698,15.0273791 15.0273791,15.597698 14.375,16.0625 L15.125,17.0625 Z" id="Path" fill="#4589ff" fill-rule="nonzero"/>
                          <path d="M17.125,12.3125 L18.3125,12.6875 C18.5843754,11.816789 18.7316689,10.9119857 18.75,10 L17.5,10 C17.5025364,10.7861723 17.375848,11.567417 17.125,12.3125 Z" id="Path" fill="#4589ff" fill-rule="nonzero"/>
                          <path d="M18.25,7.25 C17.9601487,6.37847791 17.5386003,5.55645852 17,4.8125 L16,5.5625 C16.4518254,6.19513423 16.8097022,6.88983641 17.0625,7.625 L18.25,7.25 Z" id="Path" fill="#4589ff" fill-rule="nonzero"/>
                          <path d="M15.0625,2.875 C14.3185415,2.33639972 13.4965221,1.91485132 12.625,1.625 L12.25,2.8125 C12.9851636,3.06529777 13.6798658,3.42317465 14.3125,3.875 L15.0625,2.875 Z" id="Path" fill="#4589ff" fill-rule="nonzero"/>
                          <path d="M12.6875,18.3125 L12.3125,17.125 C11.567417,17.375848 10.7861723,17.5025364 10,17.5 L10,18.75 C10.9083742,18.695884 11.8088639,18.5492927 12.6875,18.3125 L12.6875,18.3125 Z" id="Path" fill="#4589ff" fill-rule="nonzero"/>
                          <rect id="_Transparent_Rectangle_" x="0" y="0" width="20" height="20"/>
                        </g>
                      </svg> 
                      <span>No</span>
                    </div>
                  </div>
                </div>
                <div v-else>
                  {{ cell }}
                </div> 
              </cv-data-table-cell>
            </cv-data-table-row>
          </template>
          <template v-else-if="data.table.length == 0">
            <cv-data-table-row v-for="rowIndex in 20" :key="`${rowIndex}`">
              <cv-data-table-cell v-for="cellIndex in 12" :key="`${cellIndex}`">
                <b-skeleton></b-skeleton>
              </cv-data-table-cell>
            </cv-data-table-row>
          </template>
      </template>

      <template slot="actions">
        <div class = "propertyTaxReportLastStatus">
          <cv-inline-loading 
            :loadedText="data.inlineLoadedTextProp"
            :loading-text="data.inLineLoadingTextProp"
            :state="data.inlineLoadingStateProp"/>
          <div class = "propertyTaxReportLastStatusUpdare"></div>  
        </div>
        <cv-toolbar>
          <cv-toolbar-search v-model="searchInput" v-on:keyup.enter="searchOnEnter"/>
        </cv-toolbar>
        <cv-data-table-action @click="dowloadFileAsExcelFile">
          <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true"><path d="M5 18L5 20 10.586 20 3 27.586 4.414 29 12 21.414 12 27 14 27 14 18 5 18z"></path><path d="M27,3H5A2,2,0,0,0,3,5V15H5V11H15v4h2V11H27v7H17v2H27v7H17v2H27a2,2,0,0,0,2-2V5A2,2,0,0,0,27,3Zm0,6H5V5H27Z"></path><title>Export Table</title></svg>
        </cv-data-table-action>
        <cv-data-table-action @click="applyPropertyTaxReportFilter">
          <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true"><path d="M18,28H14a2,2,0,0,1-2-2V18.41L4.59,11A2,2,0,0,1,4,9.59V6A2,2,0,0,1,6,4H26a2,2,0,0,1,2,2V9.59A2,2,0,0,1,27.41,11L20,18.41V26A2,2,0,0,1,18,28ZM6,6V9.59l8,8V26h4V17.59l8-8V6Z"></path><title>Filter</title></svg>
        </cv-data-table-action>
        <cv-data-table-action @click="loadPropertyTaxDataAction">
          <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true"><path d="M6 18L7.41 19.41 15 11.83 15 30 17 30 17 11.83 24.59 19.41 26 18 16 8 6 18zM6 8V4H26V8h2V4a2 2 0 00-2-2H6A2 2 0 004 4V8z"></path><title>Upload</title></svg>
        </cv-data-table-action>
      </template>
    </cv-data-table>
  </div>


</template>

<script>
import PropertyTaxReportService from '@/vue-ui/services/reports/PropertyTaxReportService.js';

const propertyTaxReportService = new PropertyTaxReportService();
const jquery = require('jquery');

export default {
  name: 'PropertyTaxReportTable',
  emits: { "apply-property-tax-report-filter": null, "load-property-tax-data-action": null, 
    "dowload-file-as-excel-file": null, "open-property-tax-account-detail": null, "search-term": null},
  props: {
    headers: Array,
    rows: Array,
    paginationData: Object,
    title: String,
    helperText: String,
    inlineLoadingState: String,
    inlineLoadedText: String,
    inLineLoadingText: String
  },
  computed: {
    columns() {
      return this.headers.map(header => header.header);
    },
    data() {
      return {table: this.rows.map(row => ({
        data: [
          row._id,
          //row.PROPERTY_TYPE,
          row.LAST_CAUSE_NUMBER,
          row.EXEMPTION_CODE,
          row.SITUS_LOCATION_ADDRESS,
          row.SITUS_LOCATION_CITY,
          row.OWNER_CITY,
          row.OWNER_STATE,
          row.TOTAL_DELINQUENT_YEARS,
          Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(row.LAST_APPRAISED_VALUE),
          Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(row.TOTAL_DUE.toFixed(2)),
          row.DETAIL ? Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(row.DETAIL.accountDetailCurrentTaxDue): 0, 
          row.DETAIL ? row.DETAIL.workOnLead : false
        ]
      })), 
      inlineLoadingStateProp: this.inlineLoadingState,
      inlineLoadedTextProp: this.inlineLoadedText,
      inLineLoadingTextProp:  this.inLineLoadingText}
    },
  },
  methods: {
    applyPropertyTaxReportFilter: function(evt){
      this.$emit("apply-property-tax-report-filter", evt);
    },
    loadPropertyTaxDataAction: function(evt){
      this.$emit("load-property-tax-data-action", evt);
    },
    dowloadFileAsExcelFile: function(evt){
      this.$emit("dowload-file-as-excel-file", evt);
    },
    openPropertyTaxAccountDetail: function(evt, appraisedValue, totalDue){
      let event = {evt: evt, appraisedValue: appraisedValue, totalDue: totalDue};
      this.$emit("open-property-tax-account-detail", event);
    }, 
    workOnLeadEvt: function(evt, accountNumber){
      propertyTaxReportService.updateWorkOnLeadStatus(accountNumber, evt);
    },
    searchOnEnter: function(evt){
      this.$emit("search-term", evt.srcElement._value.trim());
    },
    propertyTaxReportOnSortEvt: function(evt){
      this.$emit("property-tax-report-on-sort-evt", evt);
    }
  }
};

</script>

<style lang="scss">
  @import '../../styles/carbon-utils';
  @import './carbon-overrides-table';

  .card-body {
    padding: 0rem !important;
    background-color: gray !important
  }

  .b-skeleton-text {
    margin-bottom: 0rem;
  }

  .card{
    border-radius: 0rem !important;
    border: 0rem !important
  }

  .b-skeleton-text{
    margin-bottom: 0rem !important
  }

  /*.bx--data-table--xs td, .bx--data-table--xs tbody tr th{
    padding-top: 0.45rem;
    padding-bottom: 0.45rem;
  }*/

  .bx--data-table--static {
    width: 100%;
  }

</style>