function returnToHome(msg, next){
    console.log(msg);
    next("/");
}

function validateJwtToken(to, from, next){

    let sessionStorage = window.sessionStorage;
    if(sessionStorage === null){
        returnToHome("Null sessionStorage", next);
    }

    let vueSessionKey = (sessionStorage !== null ? JSON.parse(sessionStorage["vue-session-key"]) : null);
    if(vueSessionKey === null){
        returnToHome("Null vueSessionKey", next);
    }

    let isValidUser = vueSessionKey.IS_USER_VALID;
    if(!isValidUser){
        returnToHome("No Valid User", next);
    }

    next();
}

module.exports.validateJwtToken = validateJwtToken;