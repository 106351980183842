<template>
  <div class = "propertyTaxReportPageClass">
      <biznafy-home-header />

      <div class="bx--loading-overlay--stop cv-loading" id="fileDownloaderLoader">
        <div id="fileDownloaderLoaderSpinner" data-loading="" class="">
            <label class="bx--visually-hidden">
                Loading   
            </label>
            <svg viewBox="0 0 100 100" class="bx--loading__svg">
                <title>Loading</title>
                <circle cx="50%" cy="50%" r="44" class="bx--loading__stroke"></circle>
            </svg>
        </div>
    </div>

      <biznafy-download-property-tax-report-modal ref="downloadPropTaxRepoRef"
        :use_label="false"
        :use_title="true"
        :use_content="true"
        :visible="true"
        :autoHideOff="true"
        :use_secondaryButton="true"
        :use_primaryButton="true"
        :use_dropTargetSlot="true"
        :primaryButtonDisabled="false"/>

      <biznafy-filter-modal
        :use_label="false"
        :use_title="true"
        :use_content="true"
        :visible="false"
        :autoHideOff="true"
        :use_secondaryButton="true"
        :use_primaryButton="true"
        @property-tax-filter-modal-primary-click="applyPropertyTaxFilters"/>

      <biznafy-account-detail-modal
        :use_label="false"
        :use_title="true"
        :use_content="true"
        :visible="false"
        :autoHideOff="true"
        :use_secondaryButton="true"
        :use_primaryButton="true"

        :detailData="detailData"

        :tittleAbstractingHeaders="tittleAbstractingHeaders"
        :tittleAbstractingPaginationData="tittleAbstractingPaginationData"
        :tittleAbstractingRows="tittleAbstractingRows"

        :holderContactHeaders="holderContactHeaders"
        :holderContactPaginationData="holderContactPaginationData"
        :holderContactRows="holderContactRows"

        :defendantHeaders="defendantHeaders"
        :defendantPaginationData="defendantPaginationData"
        :defendantRows="defendantRows"

        :documentHeaders="documentHeaders"
        :documentPaginationData="documentPaginationData"
        :documentRows="documentRows"

        :mediaHeaders="mediaHeaders"
        :mediaPaginationData="mediaPaginationData"
        :mediaRows="mediaRows"

        @property-tax-account-detail-modal-primary-click="saveAccountDetailEvt"
        @municipal-liens-historical-evt="municipalLiensHistoricalEvt"
        @property-valuetion-evt="propertyValuetionEvt"
        @dues-and-liens-evt="duesAndLiensEvt"

        @upload-pdf-file-evt="uploadPdfFileEvt"

        @tittle-abstracting-add-new-row-evt="tittleAbstractingAddNewRowEvt"
        @tittle-abstracting-delete-row-evt="tittleAbstractingDeleteRowEvt"
        @tittle-on-sort-evt="tittleOnSortEvt"

        @holder-contact-add-new-row-evt="holderContactAddNewRowEvt"
        @holder-contact-delete-row-evt="holderContactDeleteRowEvt"

        @defendant-add-new-row-evt="defendantAddNewRowEvt"
        @defendant-delete-row-evt="defendantDeleteRowEvt"

        @document-add-new-row-evt="documentAddNewRowEvt"
        @document-delete-row-evt="documentDeleteRowEvt"

        @media-add-new-row-evt="mediaAddNewRowEvt"
        @media-delete-row-evt="mediaDeleteRowEvt"/>

      <biznafy-file-uploader-modal
        id = "fileDataBaseImportation"
        ref = "biznafyFileUploaderModalRef"
        :acceptFile="acceptFile"
        :use_label="false"
        :use_title="true"
        :use_content="true"
        :visible="false"
        :autoHideOff="true"
        :use_secondaryButton="true"
        :use_primaryButton="true"
        :use_dropTargetSlot="true"
        :primaryButtonDisabled="primaryButtonDisabled"
        @property-tax-report-upload-file="propertyTaxReportUploadFile"
        @property-tax-file-uploader-ready="fileUploaderReady"/>

      <BiznafyMunicipalLiensHistoryModal :use_label="false" :use_title="true" :use_content="true"
        :visible="false" :autoHideOff="true" :use_secondaryButton="true" :use_primaryButton="true"
        :use_dropTargetSlot="true" :historicalData="historicalData"/>  

      <BiznafyPropertyValuetionModal :use_label="false" :use_title="true" :use_content="true" :visible="false"
        :autoHideOff="true" :use_secondaryButton="true" :use_primaryButton="true" :use_dropTargetSlot="true"
        :costRows="costRows" :costPaginationData="costPaginationData" :costHeaders="costHeaders"/>  

      <BiznafyDuesAndLiensModal :use_label="false" :use_title="true" :use_content="true" :visible="false"
        :autoHideOff="true" :use_secondaryButton="true" :use_primaryButton="true" :use_dropTargetSlot="true"/>  

    <div class="bx--grid bx--grid--full-width landing-page">
      <div class="bx--grid bx--grid--full-width bx--grid--no-gutter ibiznafy-header-padding">
        <div class="bx--row sensitive-part-page__r1">
          <div class="bx--col-lg-16 ibiznafy-table-padding">
            <property-tax-report-table
              :headers="headers"
              :rows="rows"
              :paginationData="paginationData"
              :inlineLoadingState="inlineLoadingState"
              :inlineLoadedText="inlineLoadedText"
              :inLineLoadingText="inLineLoadingText"
              :use_content="true"
              :use_primaryButton="true"
              :use_secondaryButton="true"
              :use_expandingSlottedData="true"
              @apply-property-tax-report-filter="openPropertyTaxReportFilterModal"
              @load-property-tax-data-action="openPropertyTaxFileLoaderModal"
              @dowload-file-as-excel-file="dowloadFileAsExcelFile"
              @open-property-tax-account-detail="openPropertyTaxAccountDetail"
              @property-tax-report-on-sort-evt="propertyTaxReportOnSortEvt"
              @search-term="searchTermEvt"
              @pagination="onPagination"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import PropertyTaxReportTable from './PropertyTaxReportTable';
    import BiznafyHomeHeader from '@/vue-ui/components/BiznafyHomeHeader';
    import BiznafyFilterModal from '@/vue-ui/components/BiznafyFilterModal';
    import BiznafyAccountDetailModal from '@/vue-ui/components/BiznafyAccountDetailModal';
    import MunicipalLiensService from '@/vue-ui/services/reports/MunicipalLiensService.js';
    import BiznafyDownloadPropertyTaxReportModal from '@/vue-ui/components/BiznafyDownloadPropertyTaxReportModal';
    import BiznafyMunicipalLiensHistoryModal from '@/vue-ui/components/BiznafyMunicipalLiensHistoryModal';
    import BiznafyPropertyValuetionModal from '@/vue-ui/components/BiznafyPropertyValuetionModal';
    import BiznafyDuesAndLiensModal from '@/vue-ui/components/BiznafyDuesAndLiensModal';
    import BiznafyFileUploaderModal from '@/vue-ui/components/BiznafyFileUploaderModal';
    import PropertyTaxReportService from '@/vue-ui/services/reports/PropertyTaxReportService.js';
    import iBiznafyWorker from '@/vue-ui/services/common/iBiznafyWorker.js';
    import DropBoxService from '@/vue-ui/services/common/DropBoxService.js';
    import Ajax from '@/vue-ui/services/common/Ajax.js';

    const propertyTaxReportService = new PropertyTaxReportService();
    const municipalLiensService = new MunicipalLiensService();
    const jquery = require('jquery');
    const $ = require('jquery');

    let pageSizeOpt = [20, 40, 60, 80, 100, 200, 400];
    let paginationData = { numberOfItems: propertyTaxReportService.getTotalRecords(), pageSizes: pageSizeOpt  };
    let inlineLoadingState = "loaded";
    let primaryButtonDisabled = false;
    let acceptFile = ".txt";
    let inlineLoadedText = "Last File Importation";
    let inLineLoadingText = "Importing Data []";
    let intervalId = null;
    let uploadedFile = null;
    let rows = [];
    let detailData = {};

    const tittleAbstractingPaginationData = { numberOfItems: 300, pageSizes: 20  };
    const holderContactPaginationData = { numberOfItems: 300, pageSizes: 20  };
    const defendantPaginationData = { numberOfItems: 300, pageSizes: 20  };
    const documentPaginationData = { numberOfItems: 300, pageSizes: 20  };
    const mediaPaginationData = { numberOfItems: 300, pageSizes: 20  };
    const costPaginationData = { numberOfItems: 300, pageSizes: 20  };

    const headers = [
        { key: 'accountNumber', header: 'Acc No' },
        //{ key: 'propertyType', header: 'Type' },
        { key: 'causeNumber', header: 'Cause No' },
        { key: 'exceptionalCode', header: 'Exc Code' },
        { key: 'situsLocationAddress', header: 'Sit Loc Addr' },
        { key: 'situsLocationCity', header: 'Sit Loc City' },
        { key: 'ownerCity', header: 'Owner City' },
        { key: 'ownerState', header: 'Owner State' },
        { key: 'delqYears', header: 'Dlq Yrs' },
        { key: 'appraisedValue', header: 'Appra Value' },
        { key: 'totalDue', header: 'Total Delinq. Due' },
        { key: 'currentTaxDue', header: 'Current Tax Due' },
        { key: 'workOnLead', header: 'Work On' }
    ];

    const tittleAbstractingHeaders = [
          { key: 'documentDate', header: 'Doc. Date' },
          { key: 'documentNumber', header: 'Doc. No.' },
          { key: 'documentType', header: 'Doc. Type' },
          { key: 'grantor', header: 'Grantor' },
          { key: 'grantee', header: 'Grantee' },
          { key: 'volume', header: 'Volume' },
          { key: 'page', header: 'Page' },
          { key: 'description', header: 'Desc.' }
    ];

    const holderContactHeaders = [
          { key: 'owner', header: 'Owner' },
          { key: 'name', header: 'Name' },
          { key: 'address', header: 'Address' },
          { key: 'city', header: 'City/State/Zip' },
          { key: 'phone', header: 'Phone' },
          { key: 'email', header: 'Email' },
          { key: 'facebook', header: 'Facebook' },
          { key: 'instagram', header: 'Instagram' },
          { key: 'other', header: 'Other' }
    ];

    const defendantHeaders = [
          { key: 'type', header: 'Type' },
          { key: 'name', header: 'Name' },
          { key: 'adress', header: 'Address' },
          { key: 'city', header: 'City' },
          { key: 'phone', header: 'Phone' },
          { key: 'email', header: 'Email' },
          { key: 'facebook', header: 'Facebook' },
          { key: 'instagram', header: 'Instagram' },
          { key: 'other', header: 'Other' },
          { key: 'processServiceType', header: 'Service Type' },
    ];

    const documentHeaders = [
          { key: 'documentType', header: 'Document Type' },
          { key: 'documentDate', header: 'Document Date' },
          { key: 'documentDescription', header: 'Document Description' },
          { key: 'documentName', header: 'Document Name' },
          { key: 'documentName', header: 'File Document Upload' },
          { key: 'documentName', header: 'File Document Download' }
    ];

    const mediaHeaders = [
          { key: 'mediaType', header: 'Media Type' },
          { key: 'mediaDate', header: 'Media Date' },
          { key: 'mediaDescription', header: 'Media Description' },
          { key: 'mediaName', header: 'Media Name' },
          { key: 'mediaName', header: 'File Document Upload' },
          { key: 'mediaName', header: 'File Document Download' }
    ];

    const costHeaders = [
          { key: 'mediaType', header: 'Media Type' },
          { key: 'mediaDate', header: 'Media Date' },
          { key: 'mediaDescription', header: 'Media Description' },
          { key: 'mediaName', header: 'Media Name' },
          { key: 'mediaName', header: 'File Document Upload' },
          { key: 'mediaName', header: 'File Document Download' }
    ];

    const sortMap = [ 
      "ACCOUNT_NUMBER", 
      "CAUSE_NUMBER", 
      "EXEMPTION_CODE",
      "SITUS_LOCATION_ADDRESS", 
      "SITUS_LOCATION_CITY",
      "OWNER_CITY", 
      "OWNER_STATE",
      "TOTAL_DELINQUENT_YEARS", 
      "LAST_APPRAISED_VALUE",
      "TOTAL_DUE", 

      //Account Detail's attributes
      "CURRENT_TAX_DUE",
    ];

    const tittleAbstractingRows = [];
    const holderContactRows = [];
    const defendantRows = [];
    const documentRows = [];
    const mediaRows = [];
    const historicalData = [];
    const costRows = [];


    export default {
        name: 'PropertyTaxReportPage',
        components: { BiznafyHomeHeader, PropertyTaxReportTable, BiznafyFilterModal, BiznafyFileUploaderModal, 
          BiznafyAccountDetailModal, BiznafyDownloadPropertyTaxReportModal, BiznafyMunicipalLiensHistoryModal,
          BiznafyPropertyValuetionModal, BiznafyDuesAndLiensModal },
        data() { return { 
            headers, rows, paginationData, detailData, primaryButtonDisabled, inlineLoadingState, 
            inlineLoadedText, inLineLoadingText,tittleAbstractingRows, tittleAbstractingPaginationData, 
            tittleAbstractingHeaders, holderContactPaginationData, holderContactHeaders, holderContactRows,
            defendantPaginationData, defendantHeaders, defendantRows, documentPaginationData, documentHeaders, 
            documentRows, mediaPaginationData, mediaHeaders, mediaRows, acceptFile, historicalData, costHeaders, 
            costPaginationData, costRows }; },
        mounted() {
          this.activeLoader = false;
          intervalId = this.reportImportationProcessSync();
        },
        methods: {
            openPropertyTaxReportFilterModal: function(){
              jquery('.iBiznafyPropertyTaxModalFilter').children(":first").addClass("is-visible");
            },
            enableLoader: function(){
              jquery('#fileDownloaderLoader').removeClass("bx--loading-overlay--stop");
              jquery('#fileDownloaderLoader').addClass("bx--loading-overlay");
              jquery('#fileDownloaderLoaderSpinner').addClass("bx--loading");
            },
            disableLoader: function(){
              jquery('#fileDownloaderLoader').removeClass("bx--loading-overlay");
              jquery('#fileDownloaderLoader').addClass("bx--loading-overlay--stop");
              jquery('#fileDownloaderLoaderSpinner').removeClass("bx--loading");
            },
            dowloadFileAsExcelFile: async function(){
              this.enableLoader();
              await this.$refs.downloadPropTaxRepoRef.generateData();
              jquery('.iBiznafyDownloadPropertyTaxReportModal').children(":first").addClass("is-visible");
              this.disableLoader();

            },
            onPagination: async function(evt){
              this.rows = [];
              let searchTerm = this.$session.get('SEARCH_TERM');
              this.$session.set('PAGE_LENGTH', evt.length);
              this.$session.set('CURRENT_PAGE', evt.page);

              if(searchTerm){
                let resp = await propertyTaxReportService.search(searchTerm, evt.page, this.$session);

                this.paginationData = { numberOfItems: resp.DATA.totalCount[0].ACCOUNT_NUMBER, pageSizes: pageSizeOpt };
                this.rows = resp.DATA.paginatedResults;
              }else {
                this.asyncUpdateTableDisplayedData(evt.page);
              }


            },
            reportImportationProcessSync: function(){
              return setInterval(() => {
                  new iBiznafyWorker().propertyTaxReportStatusSync(intervalId).then(resp => {
                    if(resp.DATA.STATUS === "importedSuccesfully"){
                      let importationDate = new Date(resp.DATA.LAST_UPDATE); 
                      let hours = importationDate.getHours();
                      let minutes = importationDate.getMinutes();
                      let seconds = importationDate.getSeconds();

                      this.inlineLoadingState = "loaded";
                      this.inlineLoadedText = "Last File Importation On " + importationDate.toDateString() + " At " + hours + ":" + minutes + ":" + seconds + " Hrs.";
                    }else if(resp.DATA.STATUS === "importing"){
                      this.inlineLoadingState = "loading";
                      this.inLineLoadingText = "[Importing Data] Total Rows: " + resp.DATA.TOTAL_RECORDS;
                    }else if(resp.DATA.STATUS === "uploading"){
                      this.inlineLoadingState = "loading";
                      this.inLineLoadingText = "Exporting & Converting File";
                    }
                  });
              }, 1000);
            },
            asyncUpdateTableDisplayedData: async function(page){
              let resp = await propertyTaxReportService.updateTableDisplayedData(page, this.$session);
              this.rows = resp.DATA.paginatedResults;
            },
            openPropertyTaxFileLoaderModal: function(){
              this.acceptFile = ".txt";
              jquery('.iBiznafyPropertyTaxFileUploaderModal').children(":first").addClass("is-visible");
              this.$session.set('FILE_UPLOADER_TYPE', "dataBaseImportationFile");
              this.primaryButtonDisabled = true;
            },
            uploadPdfFileEvt: async function(evt){
              let dropBoxService = new DropBoxService();
              let fileExtension = this.$session.get("FILE_EXTENSION");
              this.acceptFile = fileExtension;

              let tokenData = await dropBoxService.generateAccessToken();
              this.$session.set('DROP_BOX_ACCESS_TOKEN', tokenData.access_token);
              this.$session.set('DROP_BOX_FILE_UUID', evt.obj);
              this.$session.set('FILE_UPLOADER_TYPE', "taxPropertyPdfFile");
              this.primaryButtonDisabled = true;

              jquery('.iBiznafyPropertyTaxFileUploaderModal').children(":first").addClass("is-visible");
            },
            applyPropertyTaxFilters: async function(){
              jquery('.iBiznafyPropertyTaxModalFilter').children(":first").removeClass("is-visible");
              this.$session.set('PROPERTY_TAX_REPORT_FILTER_DATA', propertyTaxReportService.buildFilterObjByEnteredData());
              this.$session.remove('SEARCH_TERM');

              this.rows = [];
              let resp = await propertyTaxReportService.count(1, this.$session);
              this.paginationData = { numberOfItems: resp.TOTAL_RECORDS, pageSizes: pageSizeOpt };

              this.asyncUpdateTableDisplayedData(1);
            },
            fileUploaderReady: function(obj){
              uploadedFile = obj.evt[0].file;

              this.primaryButtonDisabled = false;
              obj.parent.$refs.fileUploader.setState(0, 'complete');
            },
            propertyTaxReportUploadFile: async function(obj){
              let fileUploaderType = this.$session.get('FILE_UPLOADER_TYPE');
              let tabType = this.$session.get('FILE_UPLOADER_TAB_TYPE');
              let ajax = new Ajax();

              if(fileUploaderType === "dataBaseImportationFile"){
                this.$refs.biznafyFileUploaderModalRef.closeModal();
                ajax.axiosUploadFile('/propertyTaxReportRoute/loadCsvDataToMongo', uploadedFile, 'propertyTaxReport', this.inlineLoadedText);
                intervalId = this.reportImportationProcessSync();
                
              }else if(fileUploaderType === "taxPropertyPdfFile"){
                let accountNumber = jquery("#accountDetailAccountNumber").val();
                let dropBoxService = new DropBoxService();
                let fileUuid = this.$session.get('DROP_BOX_FILE_UUID');
                let index = jquery(`#index-${fileUuid}`).val();

                let dropboxBody = await dropBoxService.uploadFile(accountNumber, uploadedFile, this.$session.get('DROP_BOX_ACCESS_TOKEN'));
                dropboxBody.uuid = fileUuid;

                let data = await dropBoxService.createDropboxReference(dropboxBody);


                if(data.status === "OK"){
                  jquery(`#${tabType}_${index}_isuploaddisabled`).val('true');
                  jquery(`#${tabType}_${index}_isdownloaddisabled`).val('false');
                  jquery(`#${tabType}_${index}_temporallink`).val(dropboxBody.path_display);

                  jquery(`#upload-${fileUuid}`).prop('disabled', 'disabled');
                  jquery(`#download-${fileUuid}`).prop('disabled', '');
                }

                obj.parent.closeModal();
              }
            },
            saveAccountDetailEvt: function(){

              let tittleAttr = ["documentDate-input-1", "documentNumber", "documentType", "grantor", "grantee", "volume", "page", "description"];
              let holderAttr = ["other", "instagram", "facebook", "email", "phone", "city", "address", "name", "owner"];
              let defendantArray = ["processServiceType", "other", "instagram", "facebook", "email", "phone", "city", "adress", "name", "type"];
              let documentArray = ["documentType", "documentDate-input-1", "documentDescription", "documentName", "documentFile", "uuid", "isuploaddisabled", "isdownloaddisabled", "temporallink"];
              let mediaArray = ["mediaType", "mediaDate-input-1", "mediaDescription", "mediaName", "mediaFile", "uuid", "isuploaddisabled", "isdownloaddisabled", "temporallink"];

              let detail = {
                  accountNumber: jquery("#accountDetailAccountNumber").val(),
                  accountDetailLegalPropertyZoning: jquery("#accountDetailLegalPropertyZoning").val(),
                  accountDetailMunicipalLiensTotalBalance: jquery("#accountDetailMunicipalLiensTotalBalance").val(),
                  accountDetailCurrentTaxDue: parseFloat(jquery("#accountDetailCurrentTaxDue").val().replace(/[^0-9.-]+/g, "")),
                  accountDetailPropertyFloodZone: jquery("#accountDetailPropertyFloodZone").val(),
                  defendantCauseNumber: jquery("#defendantCauseNumber").val(),
                  defendantCaseStatus: jquery("#defendantCaseStatus").val(),
                  defendantCourtNo: jquery("#defendantCourtNo").val(),
                  defendantFileDate: jquery("#defendantFileDate").val(),
                  defendantJuditialNumber: jquery("#defendantJuditialNumber").val(),
                  defendantJudgment: jquery("#defendantJudgment").val(),
                  defendantOrderTaxSaleDate: jquery("#defendantOrderTaxSaleDate").val(),
                  workOnLead:  jquery("#workOnLeadCheck").prop("checked"),
                  accountDetailChangesDate: jquery("#accountDetailChangesDate").val(),

                  tittleAbstractingTable: this.getAllDataFromSubTable("tittleAbstracting", this.tittleAbstractingRows, tittleAttr),
                  holderTable: this.getAllDataFromSubTable("holder", this.holderContactRows, holderAttr),
                  defendantTable: this.getAllDataFromSubTable("defendant", this.defendantRows, defendantArray),
                  documentTable: this.getAllDataFromSubTable("document", this.documentRows, documentArray),
                  mediaTable: this.getAllDataFromSubTable("media", this.mediaRows, mediaArray)
              };

              let saved = propertyTaxReportService.saveDetail(detail);
              if(saved.status === "OK" ){
                jquery('.iBiznafyAccountDetail').children(":first").removeClass("is-visible");
                let currentPage = this.$session.get('CURRENT_PAGE') === undefined ? 1 : this.$session.get('CURRENT_PAGE');
                let currentPageLength = this.$session.get('PAGE_LENGTH');

                this.onPagination({start: (currentPage * currentPageLength ) - currentPageLength - 1 , 
                  page: currentPage, length: currentPageLength});
              }

              jquery("#workOnLeadCheck").prop("checked", false);
            },
            openPropertyTaxAccountDetail: async function(obj){
              let accountDetail = propertyTaxReportService.getUniqueById(obj.evt.srcElement.id);
              let dropBoxService = new DropBoxService();
              let uuid;

              let newDetailData = {
                accountDetailAccountNumber: accountDetail.ACCOUNT_NUMBER,
                accountDetailCauseNumber: accountDetail.CAUSE_NUMBER,
                accountDetailExcemptCode: accountDetail.EXEMPTION_CODE,
                accountDetailBankruptcyNo: accountDetail.BANKRUPTCY_NO,
                accountDetailOwnerName: accountDetail.OWNER_NAME_1 + ' ' + accountDetail.OWNER_NAME_2,
                accountDetailOwnerAddress: accountDetail.OWNER_ADDRESS_1 + ' ' + accountDetail.OWNER_ADDRESS_2 + ' ' + accountDetail.OWNER_ADDRESS_3,
                accountDetailOwnerCity: accountDetail.OWNER_CITY,
                accountDetailOwnerState: accountDetail.OWNER_STATE,
                accountDetailSitusLocAddress: accountDetail.SITUS_LOCATION_ADDRESS,
                accountDetailSitusLocCity: accountDetail.SITUS_LOCATION_CITY,
                accountDetailSitusLocState: accountDetail.SITUS_LOCATION_STATE,
                accountDetailMunicipalLiensTotalBalance: Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(accountDetail.LIENS_TOTAL_BALANCE),
                accountDetailLegalDescription: accountDetail.LEGAL_DESC_1 + ' ' + accountDetail.LEGAL_DESC_2 + ' ' + accountDetail.LEGAL_DESC_3 + ' ' + accountDetail.LEGAL_DESC_4 + ' ' + accountDetail.LEGAL_DESC_5,
                accountDetailTotalDue: obj.totalDue,
                accountDetailAppraisedValue: obj.appraisedValue,

                accountDetailLegalPropertyZoning: !jquery.isEmptyObject(accountDetail.DETAIL) ? accountDetail.DETAIL[0].accountDetailLegalPropertyZoning : "",
                accountDetailPropertyFloodZone: !jquery.isEmptyObject(accountDetail.DETAIL) ? accountDetail.DETAIL[0].accountDetailPropertyFloodZone : "",
                accountDetailChangesDate: !jquery.isEmptyObject(accountDetail.DETAIL) ? accountDetail.DETAIL[0].accountDetailChangesDate : "",
                accountDetailCurrentTaxDue: !jquery.isEmptyObject(accountDetail.DETAIL) ? accountDetail.DETAIL[0].accountDetailCurrentTaxDue : "",
                defendantCauseNumber: !jquery.isEmptyObject(accountDetail.DETAIL) ? accountDetail.DETAIL[0].defendantCauseNumber : "",
                defendantCaseStatus: !jquery.isEmptyObject(accountDetail.DETAIL) ? accountDetail.DETAIL[0].defendantCaseStatus : "",
                defendantCourtNo: !jquery.isEmptyObject(accountDetail.DETAIL) ? accountDetail.DETAIL[0].defendantCourtNo : "",
                defendantFileDate: !jquery.isEmptyObject(accountDetail.DETAIL) ? accountDetail.DETAIL[0].defendantFileDate : "",
                defendantJuditialNumber: !jquery.isEmptyObject(accountDetail.DETAIL) ? accountDetail.DETAIL[0].defendantJuditialNumber : "",
                defendantJudgment: !jquery.isEmptyObject(accountDetail.DETAIL) ? accountDetail.DETAIL[0].defendantJudgment : "",
                defendantOrderTaxSaleDate: !jquery.isEmptyObject(accountDetail.DETAIL) ? accountDetail.DETAIL[0].defendantOrderTaxSaleDate : "",
                workOnLead: false
              }

              let workOnLeadCheck = !jquery.isEmptyObject(accountDetail.DETAIL) ? accountDetail.DETAIL[0].workOnLead : false;
              jquery("#workOnLeadCheck").prop("checked", workOnLeadCheck);

              this.detailData = newDetailData;
              let accountDetailObj = accountDetail.DETAIL[0];
              this.tittleAbstractingRows = !jquery.isEmptyObject(accountDetail.DETAIL) ?
                accountDetailObj.tittleAbstractingTable : [{accountNumber: "", documentDate: "", documentType: "",
                grantor: "", grantee: "", documentNumber: "", volume: "", page: "", description: ""}];

              this.holderContactRows = !jquery.isEmptyObject(accountDetail.DETAIL) ?
                accountDetailObj.holderTable : [{owner: "", name: "", address: "", city: "", phone: "", 
                email: "", facebook: "", instagram: "", other: ""}];

              this.defendantRows = !jquery.isEmptyObject(accountDetail.DETAIL) ?
                accountDetailObj.defendantTable : [{type: "", name: "", adress: "", city: "", phone:"", email:"", facebook:"", 
                instagram:"", other: "", processServiceType: ""}];

              uuid = await dropBoxService.generateUuid();
              this.documentRows = !jquery.isEmptyObject(accountDetail.DETAIL) ?
                accountDetailObj.documentTable : [{documentType: "", documentDate: "", documentDescription: "", documentName: "", 
                documentFile:"", uuid: uuid, isuploaddisabled: "false", isdownloaddisabled: "true", temporallink: ""}];

              uuid = await dropBoxService.generateUuid();
              this.mediaRows = !jquery.isEmptyObject(accountDetail.DETAIL) ?
                accountDetailObj.mediaTable  : [{mediaType: "", mediaDate: "", mediaDescription: "", mediaName: "", 
                mediaFile:"", uuid: uuid, isuploaddisabled: "false", isdownloaddisabled: "true", temporallink: ""}];

              jquery('.iBiznafyAccountDetail').children(":first").addClass("is-visible");
            }, 
            propertyTaxReportOnSortEvt: async function(evt){
              let searchTerm = this.$session.get('SEARCH_TERM');
              let currentPage = this.$session.get('CURRENT_PAGE');
              let sortObj = {};
              let sortOrder = evt.order === "ascending" ? 1 : evt.order === "descending" ? -1 : 0;

              sortObj[sortMap[evt.index]] = evt.order === "ascending" ? 1 : evt.order === "descending" ? -1 : 0;
              if(sortOrder === 0){
                this.$session.remove("PROPERTY_TAX_REPORT_SORT_OBJ");
              }else {
                this.$session.set("PROPERTY_TAX_REPORT_SORT_OBJ", sortObj);
              }


              if(searchTerm){
                let resp = await propertyTaxReportService.search(searchTerm, currentPage, this.$session);

                this.rows = [];
                this.paginationData = { numberOfItems: resp.DATA.totalCount[0].ACCOUNT_NUMBER, pageSizes: pageSizeOpt };
                this.rows = resp.DATA.paginatedResults;
              }else {
                this.applyPropertyTaxFilters();
              }
            },
            searchTermEvt: async function(searchTerm){
              if(searchTerm){
                this.$session.set('SEARCH_TERM', searchTerm);

                this.rows = [];
                let resp = await propertyTaxReportService.search(searchTerm, 1, this.$session);
                this.paginationData = { numberOfItems: resp.DATA.totalCount[0].ACCOUNT_NUMBER, pageSizes: pageSizeOpt };
                this.rows = resp.DATA.paginatedResults;
              }else {
                this.$session.remove('SEARCH_TERM');
                this.applyPropertyTaxFilters();
              }
            },
            tittleAbstractingAddNewRowEvt: function(){
              console.log(this.tittleAbstractingRows);
              let attributeArray = ["documentDate-input-1", "documentNumber", "documentType", "grantor", "grantee", "volume", "page", "description", "_id", "accountNumber"];
              let tableData = this.getAllDataFromSubTable("tittleAbstracting", this.tittleAbstractingRows, attributeArray);


              let newRow = { accountNumber: "", documentDate: "", documentType: "", grantor: "", grantee: "", 
                documentNumber: "", volume: "", page: "", description: "", accountNumber: jquery("#accountDetailAccountNumber").val() };

              tableData.push(newRow);
              this.tittleAbstractingRows = tableData;
            },
            holderContactAddNewRowEvt: function() {
              let attributeArray = ["other", "instagram", "facebook", "email", "phone", "city", "address", "name", "owner", "_id", "accountNumber"];
              let tableData = this.getAllDataFromSubTable("holder", this.holderContactRows, attributeArray);

              let newRow = {owner: "", name: "", address: "", city: "", phone: "", 
                email: "", facebook: "", instagram: "", other: "", accountNumber: jquery("#accountDetailAccountNumber").val()};

              tableData.push(newRow);  
              this.holderContactRows = tableData;
            },
            defendantAddNewRowEvt: function(){
              let attributeArray = ["processServiceType", "other", "instagram", "facebook", "email", "phone", "city", "adress", "name", "type", "_id", "accountNumber"];
              let tableData = this.getAllDataFromSubTable("defendant", this.defendantRows, attributeArray);

              let newRow = {type: "", name: "", adress: "", city: "", phone:"", email:"", facebook:"", 
                instagram:"", other: "", processServiceType: "", accountNumber: jquery("#accountDetailAccountNumber").val()};

              tableData.push(newRow);
              this.defendantRows = tableData;
            },
            documentAddNewRowEvt: async function(){
              let dropBoxService = new DropBoxService();
              let uuid = await dropBoxService.generateUuid();

              let attributeArray = ["documentType", "documentDate-input-1", "documentDescription", "documentName", "documentFile", "uuid", "isuploaddisabled", 
                "isdownloaddisabled", "temporallink"];
              let tableData = this.getAllDataFromSubTable("document", this.documentRows, attributeArray);

              let newRow = {documentType: "", documentDate: "", documentDescription: "", documentName: "", documentFile:"", uuid: uuid,
                isuploaddisabled: "false", isdownloaddisabled: "true", temporallink: ""};

              tableData.push(newRow);
              this.documentRows = tableData;
            },
            mediaAddNewRowEvt: async function(){
              let dropBoxService = new DropBoxService();
              let uuid = await dropBoxService.generateUuid();

              let attributeArray = ["mediaType", "mediaDate-input-1", "mediaDescription", "mediaName", "mediaFile", "uuid", "isuploaddisabled", 
                "isdownloaddisabled", "temporallink"];
              let tableData = this.getAllDataFromSubTable("media", this.mediaRows, attributeArray);

              let newRow = {mediaType: "", mediaDate: "", mediaDescription: "", mediaName: "", mediaFile:"", uuid: uuid,
                isuploaddisabled: "false", isdownloaddisabled: "true", temporallink: ""};

              tableData.push(newRow);
              this.mediaRows = tableData;
            },
            getAllDataFromSubTable(subtableName, subtableData, attributeArray){
              let i, j, objArray = [];
              for(i = 0; i < subtableData.length; i ++){
                let obj = {};
                for(j = 0; j< attributeArray.length; j++){
                  let value = jquery(`#${subtableName}_${i}_${attributeArray[j]}`).val();
                  let key = attributeArray[j].replace("-input-1", "");
                  obj[key] = (value === "undefined" ? "" : value);
                }
                objArray.push(obj);
              }
              return objArray;
            },
            tittleOnSortEvt: function(sortBy){
              let attributeArray = ["documentDate-input-1", "documentNumber", "documentType", "grantor", "grantee", "volume", "page", "description", "_id", "accountNumber"];
              let tittleAbstractingTable = this.getAllDataFromSubTable("tittleAbstracting", this.tittleAbstractingRows, attributeArray);

              tittleAbstractingTable.sort((a, b) => {
                let aParts = a.documentDate.split('/');
                let bParts = b.documentDate.split('/');

                let formattedA = aParts[2] + '-' + (aParts[0])+ '-' + (aParts[1]);
                let formattedB = bParts[2] + '-' + (bParts[0]) + '-' + (bParts[1]);

                var dateA = new Date(formattedA);
                var dateB = new Date(formattedB);

                if (sortBy.order === "descending"){
                  return dateB > dateA ? 1 : -1;
                }else if(sortBy.order === "ascending"){
                  return dateA > dateB ? 1 : -1;
                }else {
                  return 0;
                }
              });

              this.tittleAbstractingRows = tittleAbstractingTable;

            },
            tittleAbstractingDeleteRowEvt: function(evt){
              this.tittleAbstractingRows = evt.obj;
            },
            holderContactDeleteRowEvt: function(evt){
              this.holderContactRows = evt.obj;
            },
            defendantDeleteRowEvt: function(evt){
              this.defendantRows = evt.obj;
            },
            documentDeleteRowEvt: function(evt){
              this.documentRows = evt.obj;
            },
            mediaDeleteRowEvt: function(evt){
              this.mediaRows = evt.obj;
            },
            municipalLiensHistoricalEvt: async function(){
              let response = await municipalLiensService.getHistoricalByAccountNumber(jquery("#accountDetailAccountNumber").val());
              this.historicalData = response;
              jquery('.iBiznafyMunicipalLiensHistoryModal').children(":first").addClass("is-visible");
            },
            propertyValuetionEvt: function (){
              jquery('.iBiznafyPropertyValuetionModal').children(":first").addClass("is-visible");
            },
            duesAndLiensEvt: function (){
              jquery('.iBiznafyDuesAndLiensModal').children(":first").addClass("is-visible");
            }
        }
    };
</script>

<style lang="scss">
  @import '../../styles/carbon-utils';
  @import './carbon-overrides';
</style>