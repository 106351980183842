<template>
  <div id="homePage">
    <biznafy-home-header />
    <div class = "switcherClass">
        <cv-content-switcher aria-label='Choose content'  @selected="onSelected">
            <cv-content-switcher-button owner-id="csb-1" :selected="selectedIndex === 0">Delinquent Tax File</cv-content-switcher-button>
            <cv-content-switcher-button owner-id="csb-2" :selected="selectedIndex === 1">Municipal Liens File</cv-content-switcher-button>
        </cv-content-switcher>
        <section style="margin: 10px 0;">
            <cv-content-switcher-content owner-id="csb-1">
            <p>This is the content for option 1</p>
            </cv-content-switcher-content>
            <cv-content-switcher-content owner-id="csb-2">
            <p>This is the content for option 2</p>
            </cv-content-switcher-content>
            <cv-content-switcher-content owner-id="csb-2" >
            <p>This is more content for option 2</p>
            </cv-content-switcher-content>
            <cv-content-switcher-content owner-id="csb-3">
            <p>This is the content for option 3</p>
            </cv-content-switcher-content>
        </section>
     </div>
  </div>  
</template>

<script>
  import BiznafyHomeHeader from '@/vue-ui/components/BiznafyHomeHeader';

  export default {
    name: 'ReportUploaderPage',
    components: { BiznafyHomeHeader }
};
</script>

<style lang="scss">
  @import '../../styles/carbon-utils';
  @import './carbon-overrides';

  .switcherClass {
    padding-left: 3rem;
  }
</style>