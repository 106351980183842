import $ from 'jquery';
import axios from 'axios';

class Ajax {
    call = (method, endPoint, requestData) => {
        let responseData =  $.ajax({
            type: method,
            async: false,
            url: endPoint,
            data: JSON.stringify(requestData),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        });
        return responseData.responseJSON;
    }

    uploadFile = (endPoint, file, filePropertyName) => {
        var data = new FormData();
        data.append(filePropertyName, file);

        let responseData = $.ajax({
            url: endPoint,
            data: data,
            cache: false,
            async: false,
            contentType: false,
            processData: false,
            method: 'POST',
            type: 'POST'
        });

        return responseData.responseJSON;
    }

    axiosUploadFile = async (endPoint, file, filePropertyName, inlineLoadedText) => {
        var formData = new FormData();
        formData.append(filePropertyName, file);

        const { data } =  await axios.post(
            endPoint,
            formData,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
              onUploadProgress: function( progressEvent ) {
                let inlineLoadedText = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
                $(".bx--inline-loading__text").html(`File [${file.name}] Uploading Progress [${inlineLoadedText}%]`);
              }.bind(this)
            }
          ).then(function(){
            console.log('SUCCESS!!');
          }).catch(function(){
            console.log('FAILURE!!');
          });

        return data;
    }
}

export default Ajax;