<template>
  <div class="iBiznafyAccountDetail">
    <cv-modal
      size="large"
      :primary-button-disabled="false"
      :visible="false"
      :auto-hide-off="autoHideOff"
      @primary-click="$emit('property-tax-account-detail-modal-primary-click', $event)"
      @secondary-click="secondayClick">

      <template v-if="use_title" slot="title"><strong>ACCOUNT DETAIL</strong></template>
      <template v-if="use_content" slot="content">
        <cv-accordion @change="actionChange" ref="acc" >
          <cv-accordion-item :open="true">
            <template slot="title"><strong>GENERAL DATA</strong></template>
            <template slot="content">
              <cv-grid kind="narrow" class = "xample">
                <cv-row>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailAccountNumber" label="Account Number" :disabled="true" :value="data.accountDetail.accountDetailAccountNumber"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailCauseNumber" label="Cause Number" :disabled="true"  :value="data.accountDetail.accountDetailCauseNumber"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailBankruptcyNo" label="Bankruptcy No." :disabled="true" :value="data.accountDetail.accountDetailBankruptcyNo"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailSitusLocAddress" label="Situs Loc. Address" :disabled="true" :value="data.accountDetail.accountDetailSitusLocAddress"/>
                  </div> </cv-column>
                </cv-row>

                <cv-row>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailSitusLocCity" label="Situs Loc. City" :disabled="true" :value="data.accountDetail.accountDetailSitusLocCity"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailSitusLocState" label="Situs Loc. State" :disabled="true" :value="data.accountDetail.accountDetailSitusLocState"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailOwnerName" label="Owner Name" :disabled="true" :value="data.accountDetail.accountDetailOwnerName"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailOwnerCity" label="Owner City" :disabled="true" :value="data.accountDetail.accountDetailOwnerCity"/>
                  </div> </cv-column>
                </cv-row>

                <cv-row>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailOwnerState" label="Owner State" :disabled="true" :value="data.accountDetail.accountDetailOwnerState"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailExcemptCode" label="Exempt. Code" :disabled="true" :value="data.accountDetail.accountDetailExcemptCode"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailOwnerAddress" label="Owner Address" :disabled="true" :value="data.accountDetail.accountDetailOwnerAddress"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailOwnerAddress" label="Owner Address" :disabled="true" :value="data.accountDetail.accountDetailOwnerAddress"/>
                  </div> </cv-column>
                </cv-row>

                <cv-row>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailLegalDescription" label="Legal Description" :disabled="true" :value="data.accountDetail.accountDetailLegalDescription"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailLegalDescription" label="Legal Description" :disabled="true" :value="data.accountDetail.accountDetailLegalDescription"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailTotalDue" label="Total Delinq. Due" :disabled="true" :value="data.accountDetail.accountDetailTotalDue"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailAppraisedValue" label="Appr. Value" :disabled="true" :value="data.accountDetail.accountDetailAppraisedValue"/>
                  </div> </cv-column>
                </cv-row>

                <cv-row>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailChangesDate" label="Current Tax Due (Date)" :disabled="true" :value="data.accountDetail.accountDetailChangesDate"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <CurrencyInput  id="accountDetailCurrentTaxDue" 
                                      label="Current Tax Due"
                                      :value="data.accountDetail.accountDetailCurrentTaxDue" 
                                      :options="{ currency: 'USD' }" 
                                      @change="accountDetailCurrentTaxDueChangeEvt"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col municipalLiensGridClass">
                    <div class="relative">
                      <div id="one">
                        <cv-text-input id = "accountDetailMunicipalLiensTotalBalance" 
                            :disabled="true" label="Property Valuation (Total)"
                            :value="data.accountDetail.accountDetailMunicipalLiensTotalBalance"/>
                      </div>
                      <div id="two">
                        <cv-icon-button kind="secondary" 
                          label = "Edit" size="md" @click="$emit('property-valuetion-evt', $event)"
                          :icon="data.editIcon" class = "municipalLiensHistoryBtnClass"/>
                      </div>
                    </div>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col municipalLiensGridClass">
                    <div class="relative">
                      <div id="one">
                        <cv-text-input id = "accountDetailMunicipalLiensTotalBalance" 
                            :disabled="true" label="Municipal Liens (Total Balance)"
                            :value="data.accountDetail.accountDetailMunicipalLiensTotalBalance"/>
                      </div>
                      <div id="two">
                        <cv-icon-button kind="secondary" 
                          label = "History" size="md" @click="$emit('municipal-liens-historical-evt', $event)"
                          :icon="data.icon" class = "municipalLiensHistoryBtnClass"/>
                      </div>
                    </div>
                  </div> </cv-column>
                  <!--
                  <cv-column > <div class="cv-grid-story__preview-col municipalLiensGridClass">
                    <div class="relative">
                      <div id="one">
                        <cv-text-input id = "accountDetailMunicipalLiensTotalBalance" 
                            :disabled="true" label="Aditional Dues And Liens (Total)"
                            :value="data.accountDetail.accountDetailMunicipalLiensTotalBalance"/>
                      </div>
                      <div id="two">
                        <cv-icon-button kind="secondary" 
                          label = "Edit" size="md" @click="$emit('dues-and-liens-evt', $event)"
                          :icon="data.editIcon" class = "municipalLiensHistoryBtnClass"/>
                      </div>
                    </div>
                  </div> </cv-column>
                  -->
                </cv-row>

                <cv-row>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailLegalPropertyZoning" label="Property Zoning"  :value="data.accountDetail.accountDetailLegalPropertyZoning"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "accountDetailPropertyFloodZone" label="Property Flood Zone" :value="data.accountDetail.accountDetailPropertyFloodZone"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                    <cv-toggle
                      id = "workOnLeadCheck"
                      :checked="data.accountDetail.workOnLead"
                      label="Work On Lead"
                      :value="value"
                      :form-item="formItem"
                      :hide-label="hideLabel">
                      <template slot="text-left">Not Started</template>
                      <template slot="text-right">In Progress</template>
                    </cv-toggle>
                  </div> </cv-column>
                </cv-row>
              </cv-grid>
            </template>
          </cv-accordion-item>
          <cv-accordion-item :open="true">
            <template slot="title"><strong>TITTLE ABSTRACTING</strong></template>
            <template slot="content">
              <biznafy-prop-tax-tittle-abstracting 
                :rows="data.tittleAbstractingDataTable" 
                :paginationData="data.tittleAbstractingPaginationObj" 
                :headers="data.tittleAbstractingHeaderList"
                @add-new-row-evt="$emit('tittle-abstracting-add-new-row-evt', $event)"
                @delete-row-evt="$emit('tittle-abstracting-delete-row-evt', $event)"
                @on-sort-evt="$emit('tittle-on-sort-evt', $event)"/>
            </template>
          </cv-accordion-item>
          <cv-accordion-item :open="true">
            <template slot="title"><strong>INTEREST HOLDER CONTACT</strong></template>
            <template slot="content">
              <biznafy-interest-holder-contact
                :rows="data.holderContactDataTable" 
                :paginationData="data.holderContactPaginationObj" 
                :headers="data.holderContactHeaderList" 
                @add-new-row-evt="$emit('holder-contact-add-new-row-evt', $event)"
                @delete-row-evt="$emit('holder-contact-delete-row-evt', $event)"/>
            </template>
          </cv-accordion-item>
          <cv-accordion-item :open="true">
            <template slot="title"><strong>TAX CASE STATUS / DEFENDANTS</strong></template>
            <template slot="content">
              <cv-grid kind="narrow">

                <cv-row>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "defendantCauseNumber" label="Cause Number" :value="data.accountDetail.defendantCauseNumber"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "defendantCaseStatus" label="Case Status" :value="data.accountDetail.defendantCaseStatus"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "defendantCourtNo" label="Court Number" :value="data.accountDetail.defendantCourtNo"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "defendantFileDate" label="File Date" :value="data.accountDetail.defendantFileDate"/>
                  </div> </cv-column>
                </cv-row>

                <cv-row>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "defendantJuditialNumber" label="Judicial Number / Judge" :value="data.accountDetail.defendantJuditialNumber"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "defendantJudgment" label="Judgment" :value="data.accountDetail.defendantJudgment"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                      <cv-text-input id = "defendantOrderTaxSaleDate" label="Order Tax Sale Date" :value="data.accountDetail.defendantOrderTaxSaleDate"/>
                  </div> </cv-column>
                  <cv-column > <div class="cv-grid-story__preview-col">
                  </div> </cv-column>
                </cv-row>
              </cv-grid>

              <cv-row class = "taxCaseDefendantRowClass">
                <cv-column > <div class="cv-grid-story__preview-col">
                  <biznafy-tax-case-defendant
                    :rows="data.defendantDataTable" 
                    :paginationData="data.defendantPaginationObj" 
                    :headers="data.defendantHeaderList" 
                    @add-new-row-evt="$emit('defendant-add-new-row-evt', $event)"
                    @delete-row-evt="$emit('defendant-delete-row-evt', $event)"/>
                </div> </cv-column>
              </cv-row>

            </template>
          </cv-accordion-item>

          <cv-accordion-item :open="true">
            <template slot="title"><strong>DOCUMENTS</strong></template>
            <template slot="content">
              <biznafy-documents
                :rows="data.documentDataTable" 
                :paginationData="data.documentPaginationObj" 
                :headers="data.documentHeaderList" 
                @add-new-row-evt="$emit('document-add-new-row-evt', $event)"
                @delete-row-evt="$emit('document-delete-row-evt', $event)"
                @upload-pdf-file-evt="$emit('upload-pdf-file-evt', $event)"/>
            </template>
          </cv-accordion-item>

          <cv-accordion-item :open="true">
            <template slot="title"><strong>MEDIA</strong></template>
            <template slot="content">
              <biznafy-media
                :rows="data.mediaDataTable" 
                :paginationData="data.mediaPaginationObj" 
                :headers="data.mediaHeaderList" 
                @add-new-row-evt="$emit('media-add-new-row-evt', $event)"
                @delete-row-evt="$emit('media-delete-row-evt', $event)"
                @upload-pdf-file-evt="$emit('upload-pdf-file-evt', $event)"/>
            </template>
          </cv-accordion-item>

        </cv-accordion>

      </template>

      <template v-if="use_otherButton" slot="other-button">Other</template>
      <template v-if="use_secondaryButton" slot="secondary-button">Close</template>
      <template v-if="use_primaryButton" slot="primary-button">Save</template>
    </cv-modal>
  </div>  
</template>

<script src="https://momentjs.com/downloads/moment.min.js"></script>
<script>
  import { CvModal } from '@carbon/vue';
  import { View20, Edit20 } from '@carbon/icons-vue'; 

  import BiznafyPropTaxTittleAbstracting from '@/vue-ui/components/BiznafyPropTaxTittleAbstracting';
  import BiznafyInterestHolderContact from '@/vue-ui/components/BiznafyInterestHolderContact';
  import BiznafyTaxCaseDefendant from '@/vue-ui/components/BiznafyTaxCaseDefendant';
  import BiznafyDocuments from '@/vue-ui/components/BiznafyDocuments';
  import BiznafyMedia from '@/vue-ui/components/BiznafyMedia';

  import CurrencyInput from '@/vue-ui/components/CurrencyInput'

  const jquery = require('jquery');

  export default {
    name: 'BiznafyAccountDetailModal',
    components: { CvModal, BiznafyPropTaxTittleAbstracting, BiznafyInterestHolderContact, 
      BiznafyTaxCaseDefendant, BiznafyDocuments, BiznafyMedia, CurrencyInput},
    props: { 
      visible: Boolean,
      closeAriaLabel: String,
      primaryButtonDisabled:Boolean,
      use_title: Boolean,
      use_content: Boolean,
      size: String,
      autoHideOff: Boolean,
      use_otherButton: Boolean,
      use_secondaryButton: Boolean,
      use_primaryButton: Boolean,
      isHidden: Boolean,

      detailData: Object,

      tittleAbstractingRows: Array,
      tittleAbstractingHeaders: Array,
      tittleAbstractingPaginationData: Object,

      holderContactRows: Array,
      holderContactHeaders: Array,
      holderContactPaginationData: Object,

      defendantRows: Array,
      defendantHeaders: Array,
      defendantPaginationData: Object,

      documentRows: Array,
      documentHeaders: Array,
      documentPaginationData: Object,

      mediaRows: Array,
      mediaPaginationData: Array,
      mediaHeaders: Object
    },
    computed: { data() {
        return {
          accountDetail: this.detailData,

          tittleAbstractingDataTable: this.tittleAbstractingRows,
          tittleAbstractingPaginationObj: this.tittleAbstractingPaginationData,
          tittleAbstractingHeaderList: this.tittleAbstractingHeaders,

          holderContactDataTable: this.holderContactRows,
          holderContactPaginationObj: this.holderContactPaginationData,
          holderContactHeaderList: this.holderContactHeaders,

          defendantDataTable: this.defendantRows,
          defendantPaginationObj: this.defendantPaginationData,
          defendantHeaderList: this.defendantHeaders,

          documentDataTable: this.documentRows,
          documentPaginationObj: this.documentPaginationData,
          documentHeaderList: this.documentHeaders,

          mediaDataTable: this.mediaRows,
          mediaPaginationObj: this.mediaPaginationData,
          mediaHeaderList: this.mediaHeaders, 

          icon: View20,
          editIcon: Edit20
        };
      }
    },
    methods: {
      closeModal: function(){
        jquery('.iBiznafyAccountDetail').children(":first").removeClass("is-visible");
      },
      secondayClick: function(){
        this.closeModal();
      },
      accountDetailCurrentTaxDueChangeEvt: function(){
        let currentDate = new Date();
        let month = currentDate.getMonth() + 1;
        let day = currentDate.getDate();
        let year = currentDate.getFullYear();

        jquery('#accountDetailChangesDate').val(
          (month >= 10 ? month : '0' + month) + '/' + 
          (day >= 10 ? day : '0' + day) + '/' + year) ;
      }
    }
  };
</script>

<style lang="scss">
  @import './carbon-overrides';
</style>
