import BackendServer from '@/vue-ui/services/common/BackendServer.js';
import axios from 'axios';
class DropBoxService {

    static REFRESH_TOKEN = 'oqjnElyBwjYAAAAAAAAAATrR726pe-qYpk1TNmQXKaas8C-ImixeZZEQ8TK_hIr2';
    static CLIEND_ID = '585mjzq90rxbrut';
    static CLIENT_SECRET = 'qthrts51nk42jk8';


    generateUuid = async () => {

        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c=='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    }

    generateAccessToken = async () => {

        const { data } =  await axios.post('https://api.dropboxapi.com/oauth2/token',
            new URLSearchParams({
                'refresh_token': DropBoxService.REFRESH_TOKEN,
                'grant_type': 'refresh_token',
                'client_id': DropBoxService.CLIEND_ID,
                'client_secret': DropBoxService.CLIENT_SECRET
            })
        );
        return data;

    }

    uploadFile = async (orderNumber, binaryData, accessToken) => {
        let uuid = await this.generateUuid();

        let dropBoxApi = { autorename: false, mode: "add", mute: false,
            path: "/LacdSoft/DelinquentTaxReport/Leads/" + orderNumber + "/" + uuid + "-" + binaryData.name,
            strict_conflict: false
        }

        const { data } = await axios.post("https://content.dropboxapi.com/2/files/upload",
            binaryData,
            { headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Dropbox-API-Arg': JSON.stringify(dropBoxApi),
                    'Content-Type': 'application/octet-stream',
            } }
        );

        return data;
    }

    createDropboxReference = async (body) => {
        const { data } = await axios.post('/dropboxRoute/creteDropboxFileReference', body); 
        return data;
    }

    createTemporalLink = async (pathFile, accessToken) => {

        const { data } = await axios.post('https://api.dropboxapi.com/2/files/get_temporary_link',
            {path: pathFile}, 
            { headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json',
            } }
        );
        return data;

    }

}

export default DropBoxService;