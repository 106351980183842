import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueSession from "vue-session";
import CarbonComponentsVue from '@carbon/vue';
import VueWorker from 'vue-worker';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(VueWorker);
Vue.use(VueSession);
Vue.use(CarbonComponentsVue);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
