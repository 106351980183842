<template>
    <biznafy-prop-tax-tittle-abstracting-table
        :headers="data.headerList"
        :rows="data.dataTable"
        :paginationData="data.paginationObj"
        :use_content="true"
        :use_primaryButton="true"
        :use_secondaryButton="true"
        :use_expandingSlottedData="true"
        @add-new-row-evt = "addNewRowEvt"
        @delete-row-evt = "deleteRowEvt"
        @on-sort-evt = "onSortEvt"/>
</template>

<script>

import BiznafyPropTaxTittleAbstractingTable from './BiznafyPropTaxTittleAbstractingTable';
export default {
    components: { BiznafyPropTaxTittleAbstractingTable  },
    name: 'BiznafyPropTaxTittleAbstracting',
    props: { rows: Array, headers: Array, paginationData: Object  },
    emits: { "add-new-row-evt": null, "delete-row-evt": null, "on-sort-evt": null },
    computed: { 
        data() { return { headerList: this.headers, paginationObj: this.paginationData, dataTable: this.rows }; }
    },
    methods: {
        addNewRowEvt: function(evt){
            this.$emit("add-new-row-evt",  { obj: evt } );
        },
        deleteRowEvt: function(evt){
            this.$emit("delete-row-evt",  { obj: evt } );
        },
        onSortEvt: function(evt){
            this.$emit("on-sort-evt", evt );
        }
    }
    
}
</script>

