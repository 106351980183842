import BackendServer from '@/vue-ui/services/common/BackendServer.js';
import axios from 'axios';

class iBiznafyWorker {

    propertyTaxReportStatusSync = async (intervalId) => {
        const { data } = await axios.post('/reportImportationProcess/lastStatus', {TYPE: "propertytaxreport"});
        console.log(data.DATA);
        if(data.DATA.STATUS !== "importedSuccesfully"){
            return data;
        }else{
            clearInterval(intervalId);
            return data;
        }
    }

}

export default iBiznafyWorker;