import Ajax from '@/vue-ui/services/common/Ajax.js';

class UserLoginService {
    doLogin = (user, password) => {
        let loginReqObj = {MAIL: user, PASSWORD: password};
        const ajax = new Ajax();

        let response = ajax.call('POST', '/user/doLogin', loginReqObj);

        return response.valid;
      }
}

export default UserLoginService;