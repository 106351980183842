<template>
  <div id="homePage">
    <biznafy-home-header />
    <biznafy-test></biznafy-test>
  </div>  
</template>

<script>
  import BiznafyHomeHeader from '@/vue-ui/components/BiznafyHomeHeader';

  export default {
    name: 'HomePage',
    components: { BiznafyHomeHeader }
};
</script>

<style lang="scss">
  @import '../../styles/carbon-utils';
  @import './carbon-overrides';
</style>