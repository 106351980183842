<template>
  <div class="iBiznafyMunicipalLiensHistoryModal">
    <cv-modal size="lg"
      :close-aria-label="closeAriaLabel"

      :visible="false"
      :auto-hide-off="autoHideOff"

      @primary-click="primaryClick"
      @secondary-click="secondayClick">

      <template v-if="use_title" slot="title">Municipal Liens (History)</template>
      <template v-if="use_content" slot="content">


        <cv-data-table
          :autoWidth="true"
          :zebra="true"
          rowSize="xs"
          @search="onSearch"
          @sort="onSort"
          @row-select-change="onRowSelectChange"
          @row-select-changes="onRowSelectChanges"
          @overflow-menu-click="onOverflowMenuClick"
          @row-expanded="onRowExpanded"
          @pagination="onPagination">

            <template v-if="useBatchActions" v-slot:batch-actions>
              <cv-button :icon="trashIcon" @click="onDelete">Delete</cv-button>
            </template>
            <template v-if="useActions" v-slot:actions>
              <cv-data-table-action @click="onAction1" aria-label="compile" alt="compile">
                <compile-icon>
                  <title>Compile</title>
                </compile-icon>
              </cv-data-table-action>
              <cv-data-table-action @click="onAction2" aria-label="debug" alt="debug">
                <debug-icon>
                  <title>Debug</title>
                </debug-icon>
              </cv-data-table-action>
              <cv-data-table-action @click="onAction3" aria-label="firmware" alt="firmware">
                <embed-icon>
                  <title>Install Firmware</title>
                </embed-icon>
              </cv-data-table-action>
            </template>
            <template v-slot:headings>
              <cv-data-table-heading heading="Seq Nbr" name="name"/>
              <cv-data-table-heading heading="Date Lien"/>
              <cv-data-table-heading heading="Date Cut" name="year"/>
              <cv-data-table-heading heading="Date Invoice" />
              <cv-data-table-heading heading="Contractor Cost" />
              <cv-data-table-heading heading="Admin Cost" />
              <cv-data-table-heading heading="Interest" />
              <cv-data-table-heading heading="Invoice Nbr" />
              <cv-data-table-heading heading="Sale Tax" />
              <cv-data-table-heading heading="Pd To Date" />
              <cv-data-table-heading heading="Balance" />
            </template>
            <template v-slot:data>
              <cv-data-table-row v-for="row in data.historicalListData" :key="row.id" :value="row.id">
                <cv-data-table-cell>{{row.seqNbr}}</cv-data-table-cell>
                <cv-data-table-cell>{{row.dateLien}}</cv-data-table-cell>
                <cv-data-table-cell>{{row.dateCut}}</cv-data-table-cell>
                <cv-data-table-cell>{{row.dateInvoice}}</cv-data-table-cell>
                <cv-data-table-cell>{{row.constractorCost}}</cv-data-table-cell>
                <cv-data-table-cell>{{row.adminCost}}</cv-data-table-cell>
                <cv-data-table-cell>{{row.interest}}</cv-data-table-cell>
                <cv-data-table-cell>{{row.invoiceNbr}}</cv-data-table-cell>
                <cv-data-table-cell>{{row.saleTax}}</cv-data-table-cell>
                <cv-data-table-cell>{{row.pdToDate}}</cv-data-table-cell>
                <cv-data-table-cell>{{row.balance}}</cv-data-table-cell>
              </cv-data-table-row>
            </template>
          </cv-data-table>

      </template>
      <template v-if="use_otherButton" slot="other-button">Other</template>
      <template v-if="use_secondaryButton" slot="secondary-button">Cancel</template>
      <template v-if="use_primaryButton" slot="primary-button">OK</template>

    </cv-modal>
  </div>
</template>

<script>
  
  import DateFormatter from '@/vue-ui/services/common/DateFormatter.js';
  import { AddFilled16 } from '@carbon/icons-vue';
  import { CvModal } from '@carbon/vue';

  const jquery = require('jquery');

  export default {
    name: 'BiznafyMunicipalLiensHistoryModal',
    components: { CvModal, AddFilled16 },
    emits: { "property-tax-file-uploader-ready": null, "property-tax-report-upload-file": null},
    props: { 
      visible: Boolean,
      closeAriaLabel: String,
      primaryButtonDisabled:Boolean,
      use_title: Boolean,
      use_content: Boolean,
      size: String,
      autoHideOff: Boolean,
      use_otherButton: Boolean,
      use_secondaryButton: Boolean,
      use_primaryButton: Boolean,
      isHidden: Boolean,
      use_dropTargetSlot: Boolean, 

      historicalData: Array,

      acceptFile: String
    },
    computed: {
      data() { return {
          isUploadDisable : this.primaryButtonDisabled, 
          accept: this.acceptFile, 
          historicalListData: this.historicalData.map(row => { 
              return {
                id:               row._id,
                seqNbr:           row.seqNbr,
                accountNumber:    row.accountNumber,
                dateCut:          new DateFormatter().getStringOutputFormat(row.dateCut),
                dateLien:         new DateFormatter().getStringOutputFormat(row.dateLien),
                dateInvoice:      new DateFormatter().getStringOutputFormat(row.dateInvoice),
                constractorCost:  Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(row.constractorCost),
                adminCost:        Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(row.adminCost),
                interest:         Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(row.interest),
                invoiceNbr:       Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(row.invoiceNbr),
                saleTax:          Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(row.saleTax), 
                pdToDate:         row.pdToDate,
                balance:          Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(row.balance)
              } 
            }
          )
        }
      }
    },
    methods: {
      closeModal: function(){
        jquery('.iBiznafyMunicipalLiensHistoryModal').children(":first").removeClass("is-visible");
        this.$refs.fileUploader.clear();
      },
      primaryClick: function(){
        this.closeModal();
      },
      secondayClick: function(){
        this.closeModal();
      },
      fileUploader: function(evt){
        this.$emit("property-tax-file-uploader-ready", {evt: evt, parent: this});
      },
      propertyTaxReportUploadFile: function(evt){
        this.$emit("property-tax-report-upload-file", {evt: evt, parent: this});
      }
    }
  };
</script>

<style lang="scss">
  @import './carbon-overrides';

  .bx--accordion--start .bx--accordion__heading {
    background-color: #222222;
    color: white;
  }

  .iBiznafyMunicipalLiensHistoryModal .bx--modal .bx--modal-container{
    width: 80%;
    max-width: 1170px
  }
</style>