<template>
  <div class="iBiznafyDownloadPropertyTaxReportModal">
    <cv-modal
      size="md"
      :close-aria-label="closeAriaLabel"
      :primary-button-disabled="false"
      :visible="false"
      :auto-hide-off="autoHideOff"
      @secondary-click="secondayClick">

      <template v-if="use_title" slot="title">Download Property Tax Report</template>
      <template v-if="use_content" slot="content">
        <json-excel ref="properyTaxExcelReport"
            :name="delinquentTaxReportFileName"
            :data="jsonData"
            :fields="jsonFields"
            worksheet="My Worksheet"/>
      </template>

    </cv-modal>
  </div>
</template>

<script>
  import PropertyTaxReportService from '@/vue-ui/services/reports/PropertyTaxReportService.js';
  import JsonExcel from "@/vue-ui/components/JsonExcel";
  import { CvModal } from '@carbon/vue';

  const propertyTaxReportService = new PropertyTaxReportService();
  const jquery = require('jquery');

  let jsonMeta = [ [{key: "charset", value: "utf-8" } ]];
  let delinquentTaxReportFileName = "";
  let jsonData = [];

  let jsonFields =  {
      "Account Number": "_id",
      "Property Type": "PROPERTY_TYPE",
      "Cause Number": "LAST_CAUSE_NUMBER",
      "Exceptional Code": "EXEMPTION_CODE",
      "Situs Location Addres": "SITUS_LOCATION_ADDRESS",
      "Situs Location City": "SITUS_LOCATION_CITY",
      "Owner City": "OWNER_CITY",
      "Owner State": "OWNER_STATE",
      "Delinquent Years": "TOTAL_DELINQUENT_YEARS",
      "Appraised Value": "LAST_APPRAISED_VALUE",
      "Total Delinq. Due": "TOTAL_DUE"
    };

  export default {
    name: 'BiznafyDownloadPropertyTaxReportModal',
    components: { CvModal, JsonExcel },
    data() { return { jsonFields, jsonData, jsonMeta, delinquentTaxReportFileName }; },
    props: { 
      visible: Boolean,
      closeAriaLabel: String,
      use_title: Boolean,
      use_content: Boolean,
      size: String,
      autoHideOff: Boolean,
      use_otherButton: Boolean,
      use_secondaryButton: Boolean,
      use_primaryButton: Boolean,
      isHidden: Boolean,
      use_dropTargetSlot: Boolean
    },
    methods: {
      closeModal: function(){
        jquery('.iBiznafyDownloadPropertyTaxReportModal').children(":first").removeClass("is-visible");
      },
      secondayClick: function(){
        this.closeModal();
      },
      generateData: async function(){
        let resp = await propertyTaxReportService.count(1, this.$session);
        let respFile = await propertyTaxReportService.downloadDataAsExcelFile(1, resp.TOTAL_RECORDS, this.$session);
        
        this.jsonData = respFile;
        this.delinquentTaxReportFileName = "DelinquentTaxReportFileName.xls";
      }
    }
  };
</script>

<style lang="scss">

</style>