<template>
  <div class="iBiznafyPropertyValuetionModal">
    <cv-modal size="lg"
      :close-aria-label="closeAriaLabel"

      :visible="false"
      :auto-hide-off="autoHideOff"

      @primary-click="primaryClick"
      @secondary-click="secondayClick">

      <template v-if="use_title" slot="title">Property Valuation</template>
      <template v-if="use_content" slot="content">
        <cv-grid kind="narrow" class = "xample">
          <cv-row>
            <cv-column > <div class="cv-grid-story__preview-col">
                <cv-text-input id = "accountDetailAccountNumber" label="CMA Value (Appraised Value)"/>
            </div> </cv-column>
            <cv-column > <div class="cv-grid-story__preview-col">
                <cv-text-input id = "accountDetailCauseNumber" label="Comp Area Sales (Sq. Ft.)"/>
            </div> </cv-column>
            <cv-column > <div class="cv-grid-story__preview-col">
                <cv-text-input id = "accountDetailAccountNumber" label="Estimated Repairs Needed"/>
            </div> </cv-column>
          </cv-row>
          <cv-row>
            <cv-column > <div class="cv-grid-story__preview-col">
                <cv-text-input id = "accountDetailCauseNumber" label="Aditional Dues (Liens)"/>
            </div> </cv-column>
            <cv-column > <div class="cv-grid-story__preview-col">
                <cv-text-input id = "accountDetailAccountNumber" label="Desired Profit Maring"/>
            </div> </cv-column>
            <cv-column > <div class="cv-grid-story__preview-col">
                <cv-text-input id = "accountDetailCauseNumber" label="Estimated Present Value"/>
            </div> </cv-column>
          </cv-row>
          <cv-row>
            <cv-column > <div class="cv-grid-story__preview-col">
              <!--
              <BiznafyPropertyValuetionAditionalCost
                :rows="data.costDataTable" 
                :paginationData="data.costPaginationObj" 
                :headers="data.costHeaderList" 
                @add-new-row-evt="$emit('cost-add-new-row-evt', $event)"
                @delete-row-evt="$emit('cost-delete-row-evt', $event)"
                @upload-pdf-file-evt="$emit('cost-pdf-file-evt', $event)"/>
              -->
            </div> </cv-column>
          </cv-row>
        </cv-grid>
      </template>
      <template v-if="use_otherButton" slot="other-button">Other</template>
      <template v-if="use_secondaryButton" slot="secondary-button">Cancel</template>
      <template v-if="use_primaryButton" slot="primary-button">Save</template>

    </cv-modal>
  </div>
</template>

<script>
  import BiznafyPropertyValuetionAditionalCost from '@/vue-ui/components/BiznafyPropertyValuetionAditionalCost';
  import DateFormatter from '@/vue-ui/services/common/DateFormatter.js';
  import { AddFilled16 } from '@carbon/icons-vue';
  import { CvModal } from '@carbon/vue';

  const jquery = require('jquery');

  export default {
    name: 'BiznafyPropertyValuetionModal',
    components: { CvModal, AddFilled16, BiznafyPropertyValuetionAditionalCost },
    emits: { "property-tax-file-uploader-ready": null, "property-tax-report-upload-file": null},
    props: { 
      visible: Boolean,
      closeAriaLabel: String,
      primaryButtonDisabled:Boolean,
      use_title: Boolean,
      use_content: Boolean,
      size: String,
      autoHideOff: Boolean,
      use_otherButton: Boolean,
      use_secondaryButton: Boolean,
      use_primaryButton: Boolean,
      isHidden: Boolean,
      use_dropTargetSlot: Boolean, 

      historicalData: Array,
      acceptFile: String,

      costRows: Array,
      costPaginationData: Array,
      costHeaders: Object
    },
    computed: {
      data() { return {
          isUploadDisable:    this.primaryButtonDisabled, 
          accept:             this.acceptFile, 
          costDataTable:      this.costRows,
          costPaginationObj:  this.costPaginationData,
          costHeaderList:     this.costHeaders,
          historicalListData: this.historicalData.map(row => { 
              return {
                id:               row._id,
                seqNbr:           row.seqNbr,
                accountNumber:    row.accountNumber,
                dateCut:          new DateFormatter().getStringOutputFormat(row.dateCut),
                dateLien:         new DateFormatter().getStringOutputFormat(row.dateLien),
                dateInvoice:      new DateFormatter().getStringOutputFormat(row.dateInvoice),
                constractorCost:  Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(row.constractorCost),
                adminCost:        Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(row.adminCost),
                interest:         Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(row.interest),
                invoiceNbr:       Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(row.invoiceNbr),
                saleTax:          Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(row.saleTax), 
                pdToDate:         row.pdToDate,
                balance:          Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(row.balance)
              } 
            }
          )
        }
      }
    },
    methods: {
      closeModal: function(){
        jquery('.iBiznafyPropertyValuetionModal').children(":first").removeClass("is-visible");
      },
      primaryClick: function(){
        this.closeModal();
      },
      secondayClick: function(){
        this.closeModal();
      },
      fileUploader: function(evt){
        this.$emit("property-tax-file-uploader-ready", {evt: evt, parent: this});
      },
      propertyTaxReportUploadFile: function(evt){
        this.$emit("property-tax-report-upload-file", {evt: evt, parent: this});
      }
    }
  };
</script>

<style lang="scss">
  @import './carbon-overrides';

  .bx--accordion--start .bx--accordion__heading {
    background-color: #222222;
    color: white;
  }

  .iBiznafyMunicipalLiensHistoryModal .bx--modal .bx--modal-container{
    width: 80%;
    max-width: 1170px
  }
</style>