class DateFormatter {

    

    getStringOutputFormat = (inputStringDate) => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let formattedDate = new Date(inputStringDate);

        let day = formattedDate.getDate();
        let month =  formattedDate.getMonth();
        var year = formattedDate.getFullYear();

        day = day < 10 ? '0' + day : day;

        return monthNames[month] + '/' + day +  '/' + year;
    }

}

export default DateFormatter;