<template>
  <div class="iBiznafyPropertyTaxModalFilter">
    <cv-modal
      size="md"
      :primary-button-disabled="false"
      :visible="false"
      :auto-hide-off="autoHideOff"
      @primary-click="$emit('property-tax-filter-modal-primary-click', $event)"
      @secondary-click="secondayClick">

      <template v-if="use_title" slot="title">Filter</template>
      <template v-if="use_content" slot="content">
       <cv-grid kind="narrow" class = "xample">

          <cv-row>
            <cv-column > <div class="cv-grid-story__preview-col">
                <cv-text-input id = "accountNumber" label="Account Number"/>
            </div> </cv-column>
            <cv-column > <div class="cv-grid-story__preview-col">
                <cv-text-input id = "propertyType" label="Property Type" value = "R"/>
            </div> </cv-column>
            <cv-column :sm="1" :md="1" :lg="1"> <div class="cv-grid-story__preview-col">
            </div> </cv-column>
          </cv-row>
          <cv-row>
            <cv-column></cv-column>
            <cv-column></cv-column>
            <cv-column :sm="1" :md="1" :lg="1">
              <div class="cv-grid-story__preview-col"></div> 
            </cv-column>
          </cv-row>
          <cv-row>
            <cv-column><div class="cv-grid-story__preview-col">
                <cv-text-input id = "delqYearLower" label="Delinquent Year (Lower)" :disabled="true" value = "0"/>
            </div></cv-column>
            <cv-column><div class="cv-grid-story__preview-col">
                <cv-text-input id = "delqYearUpper" label="Delinquent Year (Upper)" :disabled="true" value = "0"/>
            </div></cv-column>
            <cv-column :sm="1" :md="1" :lg="1">
              <div class="cv-grid-story__preview-col">
                <cv-checkbox id = "delqYearCheck" @change="delqYearCheck"/>
              </div>
            </cv-column>
          </cv-row>
          <cv-row>
            <cv-column>
              <div class="cv-grid-story__preview-col">
                <cv-text-input id = "appraisedValueLower" label="Appraised Value (Lower)" :disabled="true" value = "0"/>
              </div>
            </cv-column>
            <cv-column>
              <div class="cv-grid-story__preview-col">
                <cv-text-input id = "appraisedValueUpper" label="Appraised Value (Upper)" :disabled="true" value = "0"/>
              </div>
            </cv-column>
            <cv-column :sm="1" :md="1" :lg="1">
              <div class="cv-grid-story__preview-col">
                <cv-checkbox id = "appraisedValueCheck" @change="appraisedValueCheck"/>
              </div>
            </cv-column>
          </cv-row>
          <cv-row>
            <cv-column>
              <div class="cv-grid-story__preview-col">
                <cv-text-input id = "totalDueLower" label="Total Due (Lower)" :disabled="true" value = "0"/>
              </div>
            </cv-column>
            <cv-column>
              <div class="cv-grid-story__preview-col">
                <cv-text-input id = "totalDueUpper" label="Total Due (Upper)" :disabled="true" value = "0"/>
              </div>
            </cv-column>
            <cv-column :sm="1" :md="1" :lg="1">
              <div class="cv-grid-story__preview-col">
                <cv-checkbox id = "totalDueCheck" :value="true" @change="totalDueCheck"/>
              </div>
            </cv-column>
          </cv-row>
          <cv-row>
            <cv-column>
              <div class="cv-grid-story__preview-col">
                <cv-text-input id = "situsLocationCity" label="Situs Loc. City"/>
              </div>
            </cv-column>
            <cv-column>
              <div class="cv-grid-story__preview-col">
                <cv-text-input id = "ownerState" label="Owner State"/>
              </div>
            </cv-column>
            <cv-column :sm="1" :md="1" :lg="1">
              <div class="cv-grid-story__preview-col">
              </div>
            </cv-column>
          </cv-row>
          <cv-row>
            <cv-column>
              <div class="cv-grid-story__preview-col">
                <cv-checkbox id = "withCauseNumber" label = "Contains Cause Number" :value="true" class = "withCauseNumber" :checked="true"/> 
              </div>
            </cv-column>
            <cv-column>
              <div class="cv-grid-story__preview-col">
                <div class="cv-grid-story__preview-col">
                  <cv-checkbox id = "withLead" label = "Working On Lead" :value="false" class = "withLead" :checked="false" /> 
              </div>
              </div>
            </cv-column>
            <cv-column :sm="1" :md="1" :lg="1">
              <div class="cv-grid-story__preview-col">
              </div>
            </cv-column>
          </cv-row>

      </cv-grid>

      </template>

      <template v-if="use_otherButton" slot="other-button">Other</template>
      <template v-if="use_secondaryButton" slot="secondary-button">Cancelar</template>
      <template v-if="use_primaryButton" slot="primary-button">Apply</template>
    </cv-modal>
  </div>  
</template>

<script>
  import { CvModal } from '@carbon/vue';
  const jquery = require('jquery');

  export default {
    name: 'BiznafyFilterModal',
    components: { CvModal },
    props: { 
      visible: Boolean,
      closeAriaLabel: String,
      primaryButtonDisabled:Boolean,
      use_title: Boolean,
      use_content: Boolean,
      size: String,
      autoHideOff: Boolean,
      use_otherButton: Boolean,
      use_secondaryButton: Boolean,
      use_primaryButton: Boolean,
      isHidden: Boolean
    },
    methods: {
      closeModal: function(){
        jquery('.iBiznafyPropertyTaxModalFilter').children(":first").removeClass("is-visible");
      },
      enablingChecks: function(evt, attrNameLower, attrNameUpper){
        if(evt){
          jquery(attrNameLower).removeAttr("disabled");
          jquery(attrNameUpper).removeAttr("disabled")
        }else{
          jquery(attrNameLower).attr("disabled", "disabled");
          jquery(attrNameLower).val("0");
          jquery(attrNameUpper).attr("disabled", "disabled");
          jquery(attrNameUpper).val("0");
        }
      },
      secondayClick: function(){
        this.closeModal();
      },
      appraisedValueCheck: function(evt){
        this.enablingChecks(evt, '#appraisedValueLower', '#appraisedValueUpper');
      },
      totalDueCheck: function(evt){
         this.enablingChecks(evt, '#totalDueLower', '#totalDueUpper');
      },
      delqYearCheck: function(evt){
        this.enablingChecks(evt, '#delqYearLower', '#delqYearUpper');
      }
    }
  };
</script>

<style lang="scss">
  @import './carbon-overrides';
</style>
