<template>
    <cv-data-table
      row-size="sm"
      :sortable="true"
      :zebra="true"
      :staticWidth="false"
      :stickyHeader="false"
      :columns="columns"
      :use_htmlData="true"
      searchLabel="Filter"
      searchPlaceholder="Filter"
      searchClearLabel="Clear filter"
      ref="tittleTable"
      class = "tittleAbstractingTableClass"
       @sort="onSort">

      <template v-slot:data>
          <template v-if="data.table.length">
            <cv-data-table-row v-for="(row, rowIndex) in data.table" :key="`${rowIndex}`" :value="`${rowIndex}`">
              <cv-data-table-cell><cv-date-picker :id="`tittleAbstracting_${rowIndex}_documentDate`"  kind="single" :value="`${row.data[1]}`"> </cv-date-picker></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`tittleAbstracting_${rowIndex}_documentNumber`"  :value="`${row.data[5]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`tittleAbstracting_${rowIndex}_documentType`"  :value="`${row.data[2]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`tittleAbstracting_${rowIndex}_grantor`"  :value="`${row.data[3]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`tittleAbstracting_${rowIndex}_grantee`"  :value="`${row.data[4]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`tittleAbstracting_${rowIndex}_volume`"  :value="`${row.data[6]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`tittleAbstracting_${rowIndex}_page`"  :value="`${row.data[7]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`tittleAbstracting_${rowIndex}_description`"  :value="`${row.data[8]}`"> </cv-text-input></cv-data-table-cell>
              <input type="hidden" :id="`tittleAbstracting_${rowIndex}__id`"  :value="`${row.data[9]}`" />
              <input type="hidden" :id="`tittleAbstracting_${rowIndex}_accountNumber`"  :value="`${row.data[10]}`" />
            </cv-data-table-row>
          </template>
      </template>

      <template slot="actions">
        <cv-data-table-action @click="addNewRowEvt">
          <AddAlt32/>
        </cv-data-table-action>
      </template>

      <template slot="batch-actions">
        <cv-button @click="deleteRowEvt">
          Delete
          <TrashCan16 class="bx--btn__icon"/>
        </cv-button>
      </template>

    </cv-data-table>

</template>

<script>

import { AddAlt32, TrashCan16 } from '@carbon/icons-vue';

const jquery = require('jquery');

export default {
  components: { AddAlt32, TrashCan16 },
  name: 'BiznafyPropTaxTittleAbstractingTable',
  emits: { "add-new-row-evt": null, "delete-row-evt": null, "on-sort-evt": null },
  props: {
    headers: Array,
    rows: Array,
    paginationData: Object,
    title: String,
    helperText: String,
    inlineLoadingState: String,
    inlineLoadedText: String,
    inLineLoadingText: String
  },
  computed: {
    columns() {
      return this.headers.map(header => header.header);
    },
    data() {
      return {
        table: this.rows.map(row => ({
            data: [
            row.accountNumber,
            row.documentDate,
            row.documentType,
            row.grantor,
            row.grantee,
            row.documentNumber,
            row.volume,
            row.page,
            row.description,
            row._id,
            row.accountNumber
        ]}))
      }
    },
  },
  methods: {
    addNewRowEvt: function(evt){
      this.$emit("add-new-row-evt",  { obj: evt } );
    },
    deleteRowEvt: function(){

      let newRows = this.rows.filter( (item, index, array) => {
        return !this.$refs.tittleTable.selectedRows.includes(index.toString());
      });

      this.$emit("delete-row-evt", newRows );
      this.$refs.tittleTable.deselect();
    },
    onSort: function(evt){
      this.$emit("on-sort-evt", evt );
    }
  }
};


</script>

<style lang="scss">
    @import '../../styles/carbon-utils';
    @import './carbon-overrides-table';
    //@import '@/vue-ui/styles/skeleton';

    .tittleAbstractingTableClass .bx--data-table-container .bx--data-table-content .bx--data-table--zebra tbody tr:not(.bx--parent-row):nth-child(even) td{
      border-top: none;
      border-bottom: none;
      background-color: rgb(0, 0, 0);
    }

    .tittleAbstractingTableClass .bx--data-table-container .bx--data-table-content .bx--data-table--zebra tbody tr:not(.bx--parent-row):nth-child(odd) td{
      border-top: none;
      border-bottom: none;
      background-color: rgb(0, 0, 0);
    }

    .tittleAbstractingTableClass .bx--data-table-container .bx--table-toolbar .bx--toolbar-content {
      background-color: rgb(0, 0, 0);
    }

</style>