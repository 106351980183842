<template>
  <div id="app">
    <cv-content id="#main-content">
      <router-view />
    </cv-content>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
};
</script>

<style lang="scss">
  @import './vue-ui/styles/carbon';
</style>
