<template>
  <div class = "loginPage">
    <biznafy-login-header/>
    <div class="bx--grid bx--grid--full-width landing-page">
      <div class="bx--row landing-page__r2">
        <div class="bx--col bx--no-gutter">
          <div class="bx--grid bx--grid--no-gutter bx--grid--full-width">
            <div class="bx--row landing-page__tab-content">
              <div class="bx--col-md-4 bx--col-lg-4 ibiznafy-login-background">
                <section class = "sv-template-view__component carbon-theme--g100">
                  <div class = "ibiznafy-login-container">
                    <cv-form @submit="onSubmit" method="post">
                          <div class="bx--row ibiznafy-row-spacing ibiznafy-row-footer-spacing">
                              <div class="bx--col ibiznafy-login-footer">
                                <h1 class="landing-page__heading ibiznafy-login-text">Log in</h1>
                              </div>
                          </div>
                          <div class="bx--row ibiznafy-row-spacing ibiznafy-row-footer-spacing">
                              <div class="bx--col ibiznafy-login-footer">
                                  <div class="bx--modal-footer bx--btn-set">
                                      <cv-text-input id="userid" :light="true" name="userid" label="User ID" placeholder="sample@sample.com" class = "loginInputClass" required/>
                                  </div>
                              </div>
                          </div>
                          <div class="bx--row ibiznafy-row-spacing ibiznafy-row-footer-spacing">
                              <div class="bx--col ibiznafy-login-footer">
                                  <div class="bx--modal-footer bx--btn-set">
                                      <cv-text-input id="password" name="password" label="Password" type="password" placeholder="Password" class = "loginInputClass"/>
                                  </div>
                              </div>
                          </div>
                          <div class="bx--row ibiznafy-row-spacing ibiznafy-row-footer-spacing">
                              <div class="bx--col ibiznafy-login-footer">
                                  <div class="bx--modal-footer bx--btn-set">
                                      <button tabindex="0" class="bx--btn bx--btn--secondary" type="button">Cancel</button>
                                      <button tabindex="0" class="bx--btn bx--btn--primary" type="submit">Continue</button>
                                  </div>
                              </div>
                          </div>
                      </cv-form>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import BiznafyLoginHeader from '@/vue-ui/components/BiznafyLoginHeader';
    import UserLoginService from '@/vue-ui/services/users/UserLoginService';
    
    const jquery = require('jquery');

    export default {
        name: 'LoginPage',
        components : { BiznafyLoginHeader },
        methods : {
          async onSubmit(e){
            const userLoginService = new UserLoginService();
            const user = jquery("#userid").val();
            const password = jquery("#password").val();

            e.preventDefault();
            this.$session.start();
            this.$session.set('IS_USER_VALID', userLoginService.doLogin(user, password));
            this.$router.push("/propertyTaxReport");
          }
        }
    };
</script>

<style lang="scss">
  @import '../../styles/carbon-utils';
  @import '../../styles/carbon-fixes';
  @import './carbon-overrides';

  .loginInputClass {
    align-items: normal;
  }
</style>