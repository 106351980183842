<template>
  <div class="iBiznafyPropertyTaxFileUploaderModal">
    <cv-modal
      size="md"
      :close-aria-label="closeAriaLabel"
      :primary-button-disabled="false"
      :visible="false"
      :auto-hide-off="autoHideOff"
      :primaryButtonDisabled="data.isUploadDisable"
      @primary-click="propertyTaxReportUploadFile"
      @secondary-click="secondayClick">

      <template v-if="use_title" slot="title">File Uploader</template>
      <template v-if="use_content" slot="content">
        <cv-file-uploader
          label="Choose files to upload"
          helperText="Select the files you want to upload"
          :accept="data.accept"
          :clear-on-reselect="true"
          :initial-state-uploading="false"
          :multiple="false"
          :removable="true"
          @change="fileUploader"
          :remove-aria-label="removeAriaLabel" ref="fileUploader">
          <template v-if="use_dropTargetSlot" slot="drop-target">
            <AddFilled16 /><strong>File Drop</strong><AddFilled16 />
          </template>
        </cv-file-uploader>
      </template>
      <template v-if="use_otherButton" slot="other-button">Other</template>
      <template v-if="use_secondaryButton" slot="secondary-button">Close</template>
      <template v-if="use_primaryButton" slot="primary-button">Upload</template>

    </cv-modal>
  </div>
</template>

<script>
  import { AddFilled16 } from '@carbon/icons-vue';
  import { CvModal } from '@carbon/vue';
  const jquery = require('jquery');

  export default {
    name: 'BiznafyFileUploaderModal',
    components: { CvModal, AddFilled16 },
    emits: { "property-tax-file-uploader-ready": null, "property-tax-report-upload-file": null},
    props: { 
      visible: Boolean,
      closeAriaLabel: String,
      primaryButtonDisabled:Boolean,
      use_title: Boolean,
      use_content: Boolean,
      size: String,
      autoHideOff: Boolean,
      use_otherButton: Boolean,
      use_secondaryButton: Boolean,
      use_primaryButton: Boolean,
      isHidden: Boolean,
      use_dropTargetSlot: Boolean, 

      acceptFile: String
    },
    computed: {
      data() { return {isUploadDisable : this.primaryButtonDisabled, accept: this.acceptFile}; }
    },
    methods: {
      closeModal: function(){
        jquery('.iBiznafyPropertyTaxFileUploaderModal').children(":first").removeClass("is-visible");
        this.$refs.fileUploader.clear();
      },
      secondayClick: function(){
        this.closeModal();
      },
      fileUploader: function(evt){
        this.$emit("property-tax-file-uploader-ready", {evt: evt, parent: this});
      },
      propertyTaxReportUploadFile: function(evt){
        this.$emit("property-tax-report-upload-file", {evt: evt, parent: this});
      }
    }
  };
</script>

<style lang="scss">
  @import './carbon-overrides';
</style>