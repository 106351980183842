<template>
    <biznafy-interest-holder-contact-table
        :headers="data.headerList"
        :rows="data.dataTable"
        :paginationData="data.paginationObj"
        :use_content="true"
        :use_primaryButton="true"
        :use_secondaryButton="true"
        :use_expandingSlottedData="true"
        @add-new-row-evt = "addNewRowEvt"
        @delete-row-evt = "deleteRowEvt"/>
</template>

<script>

import BiznafyInterestHolderContactTable from './BiznafyInterestHolderContactTable';
export default {
    components: { BiznafyInterestHolderContactTable  },
    name: 'BiznafyPropTaxTittleAbstracting',
    props: { rows: Array, headers: Array, paginationData: Object  },
    emits: { "add-new-row-evt": null, "delete-row-evt": null },
    computed: { 
        data() { return { headerList: this.headers, paginationObj: this.paginationData, dataTable: this.rows }; }
    },
    methods: {
        addNewRowEvt: function(evt){
            this.$emit("add-new-row-evt",  { obj: evt } );
        },
        deleteRowEvt: function(evt){
            this.$emit("delete-row-evt",  { obj: evt } );
        }
    }
    
}
</script>

