import Vue from 'vue';
import Router from 'vue-router';

import PropertyTaxReportPage from './vue-ui/views/PropertyTaxReportPage';
import LoginPage from './vue-ui/views/LoginPage/LoginPage.vue';
import HomePage from './vue-ui/views/HomePage';
import ReportUploaderPage from './vue-ui/views/ReportUploaderPage';

const jwtService = require('./vue-ui/services/jwtService.js');

Vue.use(Router);

export default new Router({
  routes: [
    { path: '/',  name: 'login-page', component: LoginPage },
    { path: '/home',  name: 'home-page',  component: HomePage, beforeEnter: jwtService.validateJwtToken },
    { path: '/propertyTaxReport',  name: 'propertyTaxReport-page', component: PropertyTaxReportPage, beforeEnter: jwtService.validateJwtToken },
    { path: '/reportUploader',  name: 'reportUploader-page', component: ReportUploaderPage, beforeEnter: jwtService.validateJwtToken }
  ]
});
