<template>
    <cv-data-table
      row-size="sm"
      :sortable="true"
      :zebra="true"
      :staticWidth="false"
      :stickyHeader="false"
      :columns="columns"
      :use_htmlData="true"
      searchLabel="Filter"
      searchPlaceholder="Filter"
      searchClearLabel="Clear filter"
      class = "tittleAbstractingTableClass"
      ref="defendantTable">

      <template v-slot:data>
          <template v-if="data.table.length">
            <cv-data-table-row v-for="(row, rowIndex) in data.table" :key="`${rowIndex}`" :value="`${rowIndex}`">

              <cv-data-table-cell>
                <cv-select :id="`defendant_${rowIndex}_type`"  v-model="row.data[0]">
                  <cv-select-option disabled selected hidden value>Choose an option</cv-select-option>
                  <cv-select-option value="owner">Owner</cv-select-option>
                  <cv-select-option value="heir">Heir</cv-select-option>
                  <cv-select-option value="holder">Lien Holder</cv-select-option>
                  <cv-select-option value="other">Other</cv-select-option>
                </cv-select>
              </cv-data-table-cell>

              <cv-data-table-cell><cv-text-input  :id="`defendant_${rowIndex}_name`"  :value="`${row.data[1]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`defendant_${rowIndex}_adress`"  :value="`${row.data[2]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`defendant_${rowIndex}_city`"  :value="`${row.data[3]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`defendant_${rowIndex}_phone`"  :value="`${row.data[4]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`defendant_${rowIndex}_email`"  :value="`${row.data[5]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`defendant_${rowIndex}_facebook`"  :value="`${row.data[6]}`" slected> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`defendant_${rowIndex}_instagram`"  :value="`${row.data[7]}`"> </cv-text-input></cv-data-table-cell>
              <cv-data-table-cell><cv-text-input  :id="`defendant_${rowIndex}_other`"  :value="`${row.data[8]}`"> </cv-text-input></cv-data-table-cell>

              <cv-data-table-cell>
                <cv-select :id="`defendant_${rowIndex}_processServiceType`"  v-model="row.data[9]">
                  <cv-select-option disabled selected hidden value = "">Choose an option</cv-select-option>
                  <cv-select-option value="certified">Certified Mail-Served</cv-select-option>
                  <cv-select-option value="served">Served In Person</cv-select-option>
                  <cv-select-option value="unserved">Unserved</cv-select-option>
                  <cv-select-option value="citation">Citation By Posting</cv-select-option>
                  <cv-select-option value="other">Other</cv-select-option>
                </cv-select>
              </cv-data-table-cell>

              <input type="hidden" :id="`defendant_${rowIndex}__id`"  :value="`${row.data[10]}`" />
              <input type="hidden" :id="`defendant_${rowIndex}_accountNumber`"  :value="`${row.data[11]}`" />

            </cv-data-table-row>
          </template>
      </template>

      <template slot="actions">
        <cv-data-table-action @click="addNewRowEvt">
          <AddAlt32/>
        </cv-data-table-action>
      </template>

      <template slot="batch-actions">
        <cv-button @click="deleteRowEvt">
          Delete
          <TrashCan16 class="bx--btn__icon"/>
        </cv-button>
        <!-- <cv-button @click="editRowEvt">
          Edit
          <Edit16 class="bx--btn__icon"/>
        </cv-button>
        -->
      </template>

    </cv-data-table>

</template>

<script>

import { AddAlt32, TrashCan16 } from '@carbon/icons-vue';

export default {
  components: { AddAlt32, TrashCan16 },
  name: 'BiznafyTaxCaseDefendantTable',
  emits: { "add-new-row-evt": null, "delete-row-evt": null },
  props: {
    headers: Array,
    rows: Array,
    paginationData: Object,
    title: String,
    helperText: String,
    inlineLoadingState: String,
    inlineLoadedText: String,
    inLineLoadingText: String
  },
  computed: {
    columns() {
      return this.headers.map(header => header.header);
    },
    data() {
      return {
        table: this.rows.map(row => ({
            data: [
            row.type,
            row.name,
            row.adress,
            row.city,
            row.phone,
            row.email,
            row.facebook,
            row.instagram,
            row.other,
            row.processServiceType,
            row._id,
            row.accountNumber
        ]}))
      }
    },
  },
  methods: {
    addNewRowEvt: function(evt){
      this.$emit("add-new-row-evt",  { obj: evt } );
    },
    deleteRowEvt: function(){
      let newRows = this.rows.filter( (item, index, array) => {
        return !this.$refs.defendantTable.selectedRows.includes(index.toString());
      });

      this.$emit("delete-row-evt", newRows );
      this.$refs.defendantTable.deselect();
    }
  }
};


</script>

<style lang="scss">
    @import '../../styles/carbon-utils';
    @import './carbon-overrides-table';
    //@import '@/vue-ui/styles/skeleton';
</style>